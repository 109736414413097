import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import axiosInstance from "../../../../hooks/axiosInstance";
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";

export default function CreateCustomManage({ show, closeModal, refreshData }) {
    const [products, setProducts] = useState([]);
    const [account, setAccount] = useState([]);
    const [originalAmount, setOriginalAmount] = useState("");

    const [formData, setFormData] = useState({
        account_id: "",
        product_id: "",
        amount: "",
        start_date: "",
        notes: "",
        status: "",
        circle: ""
    });

    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user/account/getAccountWithoutPaginate');
                setAccount(response.data.data.account);
            } catch (error) {
                toast.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
                try {
                    const response = await axiosInstance.get(`/user/custom_product/fetch_no_paginate`);
                    setProducts(response.data.data.products);
                } catch (error) {
                    console.error("Error fetching subcategories", error);
                }
        };

        fetchProducts();
    }, []);

    const resetForm = () => {
        setFormData({
            account_id: "",
            product_id: "",
            amount: "",
            start_date: "",
            notes: "",
            circle: "",
            status: ""
        });
        setValidationError({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "amount") {
            // Remove any commas in the value to keep the raw number
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.replace(/,/g, ""), // Remove commas before saving
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };
    // Handle account selection change
    const handleAccountChange = (selectedOption) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            account_id: selectedOption ? selectedOption.value : "",
        }));
    };

    const accountOptions = account.map((acc) => ({
        value: acc.id,
        label: acc.company_name
    }));

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        setIsLoading(true);
        try {
            const startDate = new Date(formData.start_date);
            const circleMonths = parseInt(formData.circle, 10);

            const endDate = new Date(startDate.setMonth(startDate.getMonth() + circleMonths));

            const updatedFormData = {
                ...formData,
                end_date: endDate.toISOString().split('T')[0]
            };
            console.log("Updated Form Data: ", updatedFormData);

            await axiosInstance.post('/user/custom_invoice/create_invoice', updatedFormData);
            refreshData();
            handleClose();
            toast.success("Invoice added successfully!");
        } catch (error) {
            console.error("Error submitting form", error);
        }
        setIsLoading(false);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    return (
        <div className="AddCustomInvoice">
            <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Custom Renewal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Form.Group className="mb-3" controlId="formAccount">
                                <h6>Account Name</h6>
                                <Select
                                    options={accountOptions}
                                    value={accountOptions.find(option => option.value === formData.account_id)}
                                    onChange={handleAccountChange}
                                    placeholder="Search Account"
                                />
                                {validationError.account_id && (
                                    <small className="text-danger my-1">{validationError.account_id[0]}</small>
                                )}
                            </Form.Group>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formProduct">
                                    <Form.Label>Product</Form.Label>
                                    <Form.Select
                                        name="product_id"
                                        value={formData.product_id}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.product}
                                    >
                                        <option value="">--select product--</option>
                                        {products.map((product) => (
                                            <option key={product.id} value={product.id}>
                                                {product.product_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.product && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.product[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAmount">
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter amount"
                                        name="amount"
                                        value={formatAmount(formData.amount)}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.amount}
                                    />
                                    {validationError.amount && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.amount[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formStartDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.start_date}
                                    />
                                    {validationError.start_date && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.start_date[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formDiscount">
                                    <Form.Label>Discount (%)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter circle"
                                        name="discount"
                                        value={formData.discount}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.discount}
                                    />
                                    {validationError.discount && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.discount[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <Form.Label>Cycles (months)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter circle"
                                        name="circle"
                                        value={formData.circle}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.circle}
                                    />
                                    {validationError.circle && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.circle[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.status}
                                    >
                                        <option value="">Select status</option>
                                        <option value="unpaid">Unpaid</option>
                                        <option value="paid">Paid</option>
                                        <option value="partly_paid">Partly Paid</option>
                                        <option value="due">Due</option>
                                    </Form.Select>
                                    {validationError.status && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.status[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={12} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formNotes">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter notes"
                                        name="notes"
                                        value={formData.notes}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.notes}
                                    />
                                    {validationError.notes && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.notes[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
