// Routes.js
import React, { useContext } from 'react';
import { AuthContext } from './context/AuthContext';
import { Navigate, Route, Routes } from 'react-router-dom';
import BaseLayout from './components/Layouts/BaseLayout';
import WebBaseLayout from './components/weblayouts/WebBaseLayout';
import DashboardPage from './Pages/Member/Dashboard';
import LoginPage from './Pages/Admin/Auth/Login';
import RegisterPage from './Pages/Member/Auth/Register';
import ForgotPasswordPage from './Pages/Admin/Auth/ForgotPassword';
import PasswordChangePage from './Pages/Admin/Auth/ChangePassword';
import MemberProfilePage from './Pages/Member/Profile';
import AdminDashboard from './Pages/Admin/Dashboard';
import VerifyEmailPage from './Pages/Admin/Auth/VerifyEmail';
import EmailVerifiedPage from './Pages/Admin/Auth/EmailVerified';

import Index from "./Pages/Admin/AccountUser/Index";
import BaseUser from "./Pages/Admin/BaseUser/index";
import Product from './Pages/Admin/ProductManagement/Product';
import Category from './Pages/Admin/CategoryManager/Category';
import Renewal from './Pages/Admin/Renewal/Renewal';
import Management from './Pages/Admin/Renewal/Management';
import UserManagement from "./Pages/Admin/User/UserManagement";
import ManageAcl from "./Pages/Admin/User/ManageAcl";
import ProtectedRoute from "./hooks/protectedRoute";
import Forbidden from "./Pages/Admin/Forbidden";
import { usePermissions } from "./context/PermissionContext";
import NotFoundPage from "./Pages/Admin/NotFoundPage";
import SubAccount from './Pages/Admin/BaseUser/SubAccount';
import CustomProduct from './Pages/Admin/ProductManagement/CustomProduct';
import CustomManagement from './Pages/Admin/Renewal/CustomManagement';
import Invoicing from './Pages/Admin/Invoicing/invoice';





const AppRoutes = () => {
        // const { isLoggedIn } = useContext(AuthContext);
      
        // if (!isLoggedIn) {
        //   return <Navigate to="/auth/login" />;
        // }
  return (
    <Routes>
      <Route path="/">
        {/* <Route index element={<Navigate to="/app/dashboard" />} /> */}
        <Route index element={<Navigate to="/auth/login" />} />
        <Route path="auth">
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="reset-password" element={<PasswordChangePage />} />
          <Route path="verify-email" element={<VerifyEmailPage />} />
          <Route path="email-verified" element={<EmailVerifiedPage />} />
        </Route>
        <Route path="app">
          <Route
            path="dashboard"
            element={
              <ProtectedRoute
                element={DashboardPage}
                layout={BaseLayout}
                permission="view_dashboard"
              />
            }
          />
          <Route
            path="profile"
            element={
              <BaseLayout>
                <MemberProfilePage />
              </BaseLayout>
            }
          />
          <Route path="base_user">
            <Route
              path=""
              element={
                <ProtectedRoute
                  element={BaseUser}
                  layout={BaseLayout}
                  permission="base_user"
                />
              }
            />
            <Route
              path="sub_account"
              element={
                <ProtectedRoute
                  element={SubAccount}
                  layout={BaseLayout}
                  permission="base_user"
                />
              }
            />

            <Route path="admin">
              <Route
                path=""
                element={
                  <ProtectedRoute
                    element={DashboardPage}
                    layout={BaseLayout}
                    permission="view_dashboard"
                  />
                }
              />
            </Route>
          </Route>

          <Route
            path="user-management"
            element={
              <ProtectedRoute
                element={UserManagement}
                layout={BaseLayout}
                permission="view_user"
              />
            }
          />
          <Route
            path="acl"
            element={
              <ProtectedRoute
                element={ManageAcl}
                layout={BaseLayout}
                permission="roles_and_permissions"
              />
            }
          />

          <Route path="account">
            <Route
              path=""
              element={
                <BaseLayout>
                  <Index />
                </BaseLayout>
              }
            />

            <Route path="admin">
              <Route
                path=""
                element={
                  <BaseLayout>
                    <AdminDashboard />
                  </BaseLayout>
                }
              />
            </Route>
          </Route>

          {/* Product Management */}
          <Route path="product-management">
            <Route
              path=""
              element={
                <BaseLayout>
                  <Product />
                </BaseLayout>
              }
            />
          </Route>

          <Route path="custom-product">
            <Route
              path=""
              element={
                <BaseLayout>
                  <CustomProduct />
                </BaseLayout>
              }
            />
          </Route>

          {/* Catgeory Management */}
          <Route path="manage-category">
            <Route
              path=""
              element={
                <BaseLayout>
                  <Category />
                </BaseLayout>
              }
            />
          </Route>

          {/* RENEWAL Management */}
          {/* <Route path="renewal">
            <Route
              path=""
              element={
                <BaseLayout>
                  <Renewal />
                </BaseLayout>
              }
            />
          </Route> */}

          {/* Renewal Management */}
          <Route path="renewal-management">
            <Route
              path=""
              element={
                <BaseLayout>
                  <Management />
                </BaseLayout>
              }
            />
          </Route>
          <Route path="custom-renewal">
            <Route
              path=""
              element={
                <BaseLayout>
                  <CustomManagement />
                </BaseLayout>
              }
            />
          </Route>

          <Route path="invoicing">
            <Route
              path=""
              element={
                <BaseLayout>
                  <Invoicing />
                </BaseLayout>
              }
            />
          </Route>
        </Route>
        <Route
          path="app/dashboard"
          element={
            <WebBaseLayout>
              <AdminDashboard />
            </WebBaseLayout>
          }
        />
      </Route>
      <Route path="/unauthorized" element={<Forbidden />} />
      <Route path="*" element={<NotFoundPage />} />{" "}
    </Routes>
  );
};

export default AppRoutes;
