import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";

export default function CreateCustomProduct({ show, closeModal, refreshPage }) {
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        product_name: "",
        status: "",
        description: "",
    });

    const resetForm = () => {
        setFormData({
            product_name: "",
            status: "",
            description: "",
        });
        setValidationError({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "amount") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.replace(/,/g, ""), // Remove commas before saving
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axiosInstance.post('/user/custom_product/create', formData);
            refreshPage();
            handleClose();
            toast.success("Product added successfully!");
        } catch (error) {
            toast.error("Error submitting form", error);
        }
        setIsLoading(false);
    };

    return (
        <div className="CreateProduct">
            <Modal show={show} onHide={handleClose} dialogClassName="modal-md">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <div>
                                <h6>Product Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="product_name"
                                value={formData.product_name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.product_name && (
                                <small className="text-danger my-1">{validationError.product_name[0]}</small>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDescription1">
                            <div>
                                <h6>Description</h6>
                            </div>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter..."
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                            {validationError.description && (
                                <small className="text-danger my-1">{validationError.description[0]}</small>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formUnitOfMeasure">
                            <div>
                                <h6>Status</h6>
                            </div>
                            <Form.Select
                                aria-label="Select Status"
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="">-- Select Status --</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </Form.Select>
                            {validationError.status && (
                                <small className="text-danger my-1">
                                    {validationError.status[0]}
                                </small>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button type="submit" variant="primary" disabled={isLoading}>
                            {isLoading ? "Submitting..." : "Submit"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
