import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./Style.css";

export default function ViewCompany({ showView, closeModal, refreshPage, subAccounts }) {
    const handleClose = () => {
        closeModal();
    };

    const [subAccount, setSubAccount] = useState(null);

    useEffect(() => {
        if (subAccounts) {
            setSubAccount(subAccounts);
        }
    }, [subAccounts]);

    const SubAccount = subAccount ? (
        <Card className="mb-3">
            <Card.Body>
                <Card.Text>
                    <Card.Title>Customer Company Details</Card.Title>

                    <strong>First Name:</strong> {subAccount.account_first_name}
                    <br />
                    <strong>Last Name:</strong> {subAccount.account_last_name}
                    <br />
                    <strong>Email:</strong> {subAccount.account_email}
                    <br />
                    <strong>Phone:</strong> {subAccount.account_phone}
                    
                </Card.Text>
            </Card.Body>
        </Card>
    ) : (
        <p>No SUb Accounts available.</p>
    );

    return (
        <div className="ViewTransaction">
            <Modal show={showView} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Sub Account</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: "15px", lineHeight: "30px" }}>
                    {SubAccount}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        style={{ backgroundColor: "#c4281b" }}
                        onClick={refreshPage}
                    >
                        Refresh
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
