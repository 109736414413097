import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import "./Style.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";
import { Spinner } from "react-bootstrap";

export default function CreateCredit({ show, closeModal, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    status: "",
  });

  const resetForm = () => {
    setFormData({
      name: "",
      description: "",
      status: "",
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axiosInstance.post("/user/category/create", formData);
      toast.success("Category created successfully!");
      handleClose();
      refreshPage();
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data.errors) {
        setValidationError(error.response.data.errors);
        toast.error("Failed to save.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="CreateCSP">
      <Modal show={show} onHide={handleClose} dialogClassName="modal-md">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <div>
                <h6>Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="name"
                value={formData.name}
                onChange={handleChange}
                required

              />
              {validationError.name && (
                <small className="text-danger my-1">
                  {validationError.name[0]}
                </small>
              )}
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formDescription">
              <div>
                <h6>Description</h6>
              </div>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter..."
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
              {validationError.description && (
                <small className="text-danger my-1">
                  {validationError.description[0]}
                </small>
              )}
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formUnitOfMeasure">
              <div>
                <h6>Status</h6>
              </div>
              <Form.Select
                aria-label="Select Status"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="">-- Select Status --</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {validationError.status && (
                <small className="text-danger my-1">
                  {validationError.status[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className=""
              style={{ backgroundColor: "#c4281b", color: "#fff" }}
              type="submit"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
