import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateUserMngt from "../../../components/Modal/Admin/User/CreateUserMngt";
import EditUserMngt from "../../../components/Modal/Admin/User/EditUserMngt";
import EditUserPassword from "../../../components/Modal/Admin/User/EditUserPassword";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [editUserDetails, setEditUserDetails] = useState(null);
  const [editUserPasswordId, setEditUserPasswordId] = useState(null);
  const [searchQuery, setSearchQuery] = useState(""); // Add search query state
  const [isGeneralLoading, setIsGeneralLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsGeneralLoading(true); // Show loader for general data fetching

      try {
        const response = await axiosInstance.get(`user/fetch-user`, {
          params: {
            page,
            search: searchQuery,
          },
        });
        setUsers(response.data.data.users.data || []);
        setPagination(response.data.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsGeneralLoading(false); 
      }
    };

    fetchUsers();
  }, [page, refreshKey, searchQuery]); // Re-run the effect when page, refreshKey, or searchQuery changes

  const handleShow = () => setShow(true);

  const handleShowEdit = (user) => {
    setEditUserDetails(user);
    setShowEdit(true);
  };

  const handleShowEditPassword = (userId) => {
    setEditUserPasswordId(userId);
    setShowEditPassword(true);
  };

  const MySwal = withReactContent(Swal);

  const handleDelete = async (userId, sub_account_email) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`user/auth/user/${userId}/${sub_account_email}}`);
        toast.success("User deleted successfully!");
        handleRefresh();
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error("Error deleting user.");
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowEditPassword(false);
    setEditUserPasswordId(null);
  };

  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pagination.last_page) {
      setPage(newPage);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {isGeneralLoading && !searchQuery ? ( // Only show loader for general data fetching
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <Oval
            height={80}
            width={80}
            color="#FF0200"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="car">
              <div className="card-body border border-dashed border-end-0 border-start-0">
                <div className="d-flex justify-content-between mb-3">
                  <div className="col-xl-4 col-md-6">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search by name, email, etc..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div>
                    <button
                      className="vehicle-button btn btn-primary"
                      style={{
                        backgroundColor: "#c4281b",
                        color: "#fff",
                        border: "none",
                      }}
                      onClick={handleShow}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i> Add New
                      User
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive-sm">
                  <table
                    className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                    id="customerTable"
                  >
                    <thead className="table-light text-muted">
                      <tr>
                        <th scope="col">#</th>
                        <th
                          className="sort"
                          data-sort="currency_name"
                          scope="col"
                        >
                          {" "}
                          Name
                        </th>
                        <th className="sort" data-sort="type" scope="col">
                          {" "}
                          Last Name
                        </th>
                        <th
                          className="sort"
                          data-sort="quantity_value"
                          scope="col"
                        >
                          {" "}
                          Email
                        </th>
                        <th
                          className="sort"
                          data-sort="quantity_value"
                          scope="col"
                        >
                          {" "}
                          Phone Number
                        </th>
                        <th
                          className="sort"
                          data-sort="quantity_value"
                          scope="col"
                        >
                          {" "}
                          Role
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list form-check-all">
                      {users.map((user, index) => (
                        <tr key={user.id}>
                          <td>{index + 1}</td>
                          <td> {user.first_name}</td>
                          <td>{user.last_name}</td>
                          <td> {user.email}</td>
                          <td> {user.phone}</td>
                          <td>
                            {user.roles.length > 0 ? (
                              user.roles.map((role) => (
                                <span
                                  key={role.id}
                                  className="badge bg-primary"
                                >
                                  {role.name}
                                </span>
                              ))
                            ) : (
                              <span className="badge bg-secondary">
                                No Role
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-apps"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleShowEdit(user)}
                                  >
                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleShowEditPassword(user.id)
                                    }
                                  >
                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                    Update Password
                                  </Link>
                                </li>
                                {user.roles.length > 0 && (
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() => handleDelete(user.id, user.email)}
                                    >
                                      <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                      Delete user
                                    </Link>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end mt-5 mb-5">
                    <div className="pagination-wrap hstack gap-2">
                      <ul className="pagination listjs-pagination mb-0">
                        {(pagination.links || []).map((link, index) => {
                          const pageNumber = link.url
                            ? new URL(link.url).searchParams.get("page")
                            : null;

                          return (
                            <li
                              key={index}
                              className={`page-item ${
                                link.active ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  pageNumber &&
                                  handlePageChange(Number(pageNumber))
                                }
                                dangerouslySetInnerHTML={{ __html: link.label }}
                              ></button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
      )}
          <CreateUserMngt
            show={show}
            closeModal={handleClose}
            refreshPage={handleRefresh}
          />
          <EditUserMngt
            showEdit={showEdit}
            closeModal={handleClose}
            refreshPage={handleRefresh}
            userDetails={editUserDetails}
          />
          <EditUserPassword
            showEditPassword={showEditPassword}
            closeModal={handleClose}
            refreshPage={handleRefresh}
            userId={editUserPasswordId} // Pass the userId here
          />
    </>
  );
};

export default UserManagement;
