import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ViewSubAccount from "../../../components/Modal/Admin/BaseUser/ViewSubAccount";
import EditSubAccount from "../../../components/Modal/Admin/BaseUser/EditSubAccount";
import axiosInstance from "../../../hooks/axiosInstance";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";

import { toast } from "react-toastify";

export default function SubAccount() {
    const MySwal = withReactContent(Swal);
    const [page, setPage] = useState();
    const [filter, setFilter] = useState("");
    const [showEdit, setShowEdit] = useState(false);
    const [showView, setShowView] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [baseUser, setBaseUser] = useState([]);
    const [SubAccount, setSubAccount] = useState([]);
    const [pagination, setPagination] = useState({});
    const [editSubAccount, setEditSubAccount] = useState(null);
    const [subAccounts, setViewSubAccount] = useState(null);
    const [selectedBaseUserId, setSelectedBaseUserId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBaseUser = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(
                    `/user/base_user/fetch_base_user_no_paginate`
                );

                setBaseUser(response.data.data.base_user || []);

            } catch (error) {
                toast.error("Error fetching users:", error);
            }
            finally {
                setLoading(false); // Hide loader after fetching
            }
        };

        fetchBaseUser();
    },[]);

    const handleBaseUserSelect = (event) => {
        setSelectedBaseUserId(event.target.value);
    };

    useEffect(() => {
        const fetchSubAccount = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(
                    `/user/sub_account/sub_account_company`,
                    {
                        params: {
                            page,
                            filter,
                            sub_account_id: selectedBaseUserId || "",
                        },
                    }
                );

                setSubAccount(response.data.data.sub_account.data || []);
                
                setPagination(response.data.data.sub_account);
            } catch (error) {
                console.error("Error fetching company details:", error);
            }
            finally {
                setLoading(false); // Hide loader after fetching
            }
        };

        fetchSubAccount();
    }, [selectedBaseUserId, page, filter, refreshKey]);

    const handleClose = () => {
        setShowEdit(false);
        setShowView(false);
        // setChangeImage(false);
    };
    const handleShowView = (sub_account) => {
        setViewSubAccount(sub_account);
        setShowView(true);
    };

    const handleShowEdit = (sub_account_id) => {
        setEditSubAccount(sub_account_id);
        setShowEdit(true);
    };

    // axiosInstance.post(`/user/company/remove-company/${companyId}`)
    const handleDeleteSubAccount = async (subAccountId, sub_account_email) => {
        const result = await MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await axiosInstance.post(`/user/sub_account/remove_sub_account/${subAccountId}/${sub_account_email}`);
                handleRefresh();
                handleClose();
                toast.success("Sub Account removed successfully!");
            } catch (error) {
                toast("Error deleting sub account:", error);
            }
        }
    };

    const handleRefresh = () => {
        setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.last_page) {
            setPage(newPage);
        }
    };

    useEffect(() => { }, [refreshKey]);

    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>
                            Manage Sub Account
                        </h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/app">Dashboard</Link>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    style={{ color: "#c4281c" }}
                                >
                                    Manage Sub Account
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}
            {loading ?(
                <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                    <Oval
                        height={80}
                        width={80}
                        color="#FF0200"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (
            <div className="row" id="contactList">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex align-items-center border-0">
                            <h5
                                className="card-title mb-0 flex-grow-1"
                                style={{ color: "#c4281b" }}
                            >
                                        Manage Sub Account
                            </h5>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div className="row">
                                <div className="col-xl-4 col-md-6">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="form-control search"
                                            placeholder="Search to customer..."
                                            value={filter}
                                            onChange={handleFilterChange}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                                <div className="col-xl-1 col-md-6"></div>

                                <div className="col-xl-4 col-md-6 ml-2">
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                Select a base user &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;{" "}
                                            </span>
                                        </div>
                                        <select
                                            className="custom-select form-control"
                                            onChange={handleBaseUserSelect}
                                        >
                                            <option value="">--select base-user--</option>
                                                {baseUser.map((baseUser) => (
                                                <option key={baseUser.id} value={baseUser.id}>
                                                    {baseUser.first_name} {baseUser.last_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6"></div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-card">
                                <table
                                    className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                                    id="customerTable"
                                >
                                    <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th
                                                className="sort"
                                                data-sort="currency_name"
                                                scope="col"
                                            >
                                                {" "}
                                                First Name
                                            </th>
                                            <th className="sort" data-sort="type" scope="col">
                                                {" "}
                                                Last Name
                                            </th>
                                            <th
                                                className="sort"
                                                data-sort="quantity_value"
                                                scope="col"
                                            >
                                                {" "}
                                                Phone
                                            </th>
                                            <th
                                                className="sort"
                                                data-sort="quantity_value"
                                                scope="col"
                                            >
                                                {" "}
                                                Email
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {/* <!--end thead--> */}
                                    <tbody className="list form-check-all">
                                            {SubAccount.map((SubAccount, index) => (
                                            <tr key={SubAccount.id}>
                                                <td>{index + 1}</td>
                                                <td> {SubAccount.account_first_name}</td>
                                                <td> {SubAccount.account_last_name}</td>
                                                <td>{SubAccount.account_phone}</td>
                                                <td> {SubAccount.account_email}</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="mdi mdi-apps"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowView(SubAccount)}
                                                                >
                                                                    <i className="ri-eye-fill text-muted me-2 align-bottom"></i>
                                                                    View Sub Account
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowEdit(SubAccount)}
                                                                >
                                                                    <i className="mdi mdi-pencil me-2 text-success"></i>{" "}
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                            {/* {customer.company_id && ( */}
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item text-danger"
                                                                    onClick={() =>
                                                                        handleDeleteSubAccount(SubAccount.sub_account_id, SubAccount.account_email)
                                                                    }
                                                                >
                                                                    <i className="mdi mdi-delete-outline me-1"></i>{" "}
                                                                    Delete sub-account
                                                                </Link>
                                                            </li>
                                                            {/* )} */}
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {/* <!--end table--> */}
                                <div className="d-flex justify-content-end mt-5 mb-5">
                                    <div className="pagination-wrap hstack gap-2">
                                        <ul className="pagination listjs-pagination mb-0">
                                            {(pagination.links || []).map((link, index) => {
                                                const pageNumber = link.url
                                                    ? new URL(link.url).searchParams.get("page")
                                                    : null;

                                                return (
                                                    <li
                                                        key={index}
                                                        className={`page-item ${link.active ? "active" : ""
                                                            }`}
                                                    >
                                                        <button
                                                            className="page-link"
                                                            onClick={() =>
                                                                pageNumber &&
                                                                handlePageChange(Number(pageNumber))
                                                            }
                                                            dangerouslySetInnerHTML={{ __html: link.label }}
                                                        ></button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            )}
            <EditSubAccount
                showEdit={showEdit}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                editSubAccount={editSubAccount}
            />
            <ViewSubAccount
                showView={showView}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                subAccounts={subAccounts}
            />
        </>
    );
}
