import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../hooks/axiosInstance";

export default function ViewInvoice({
    showView,
    closeModal,
    refreshPage,
    Invoice,
}) {
    const handleClose = () => {
        closeModal();
    };

    const [invoices, setInvoices] = useState([]);

    // useEffect(() => {
    //     if (Invoice) {
    //         setInvoices(Invoice);
    //         console.log(Invoice, 88);

    //     }
    // }, [Invoice]);

    useEffect(() => {
        // console.log(Invoice, 767);
        
        const fetchOrders = async () => {
            if (Invoice) {
                try {
                    const response = await axiosInstance.get("/user/invoice_custom_service/fetch_no_apge", {
                        params: {
                            account_id: Invoice, // Fetch orders with the same order_code
                        },
                    });
                    setInvoices(response.data.data.invoices); // Adjust based on your API response structure
                    
                } catch (error) {
                    console.error("Error fetching orders:", error);
                } finally {
                    // setLoading(false);
                }
            }
        };

        fetchOrders();
    }, [Invoice]);

    // Function to format numbers with commas
    const formatAmount = (amount) => {
        if (amount !== undefined && amount !== null) {
            return new Intl.NumberFormat().format(amount);
        }
        return amount;
    };

    const InvoiceCards = invoices.length > 0 ? (
        invoices.map((invoice, index) => (
        <Card key={invoice.id} className="mb-3">
            <Card.Body>
                {/* <Card.Title>{products.product_name}</Card.Title> */}
                <Card.Text>
                    <strong>Account Name:</strong> {invoice.account?.company_name}
                    <br />
                        <strong>Product Name:</strong>
                        {invoice.type === 'custom'
                            ? invoice.custom_product?.product_name
                            : invoice.product?.product_name}
                    <br />
                    <strong>Amount:</strong> {formatAmount(invoice.amount)}
                    <br />
                    <strong>Amount Paid:</strong> {formatAmount(invoice.amount_paid)}
                    <br />
                    <strong>Balance:</strong> {formatAmount(invoice.balance)}
                    <br />
                    <strong>Discount:</strong> {invoice.discount}
                    <br />
                    <strong>Status:</strong> {invoice.status}
                </Card.Text>
            </Card.Body>
        </Card>
        ))
    ) : (
        <p>No order details available.</p>
    );
    return (
        <div className="ViewTransaction">
            <Modal show={showView} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Invoice Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: "15px", lineHeight: "30px" }}>
                    {InvoiceCards}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        style={{ backgroundColor: "#c4281b", border: "none" }}
                        onClick={refreshPage}
                    >
                        Refresh
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
