import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../../../hooks/axiosInstance";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";
import CreateCustomProduct from "../../../components/Modal/Admin/ProductManagement/CreateCustomProduct";
import EditCustomProduct from "../../../components/Modal/Admin/ProductManagement/EditCustomProduct";
import ViewCustomProduct from "../../../components/Modal/Admin/ProductManagement/ViewCustomProduct";

export default function CustomProduct() {
    const MySwal = withReactContent(Swal);
    const [products, setProducts] = useState([]);
    const [filter, setFilter] = useState("");
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showView, setShowView] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [editProduct, setEditProduct] = useState(null);
    const [viewProduct, setViewProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/user/custom_product/fetch`, {
                    params: { page, filter },
                });
                setProducts(response.data.data.products.data);
                setPagination(response.data.data.products);
            } catch (error) {
                console.error("Error fetching products!", error);
            }
            setLoading(false);
        };

        fetchProducts();
    }, [page, filter, refreshKey]);

    const handleDelete = async (id) => {
        const result = await MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await axiosInstance.post(`/user/custom_product/delete/${id}`);
                handleRefresh();
                handleClose();
                toast.success("Product removed successfully!");
            } catch (error) {
                toast("Error deleting product:", error);
            }
        }
    };

    const handleShow = () => setShow(true);

    const handleShowView = (product) => {
        setViewProduct(product);
        setShowView(true);
    };

    const handleShowEdit = (product) => {
        setEditProduct(product);
        setShowEdit(true);
    };

    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
        setShowView(false);
    };

    const handleRefresh = () => setRefreshKey((prev) => prev + 1);

    const handleFilterChange = (event) => setFilter(event.target.value);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.last_page) {
            setPage(newPage);
        }
    };

    const formatAmount = (amount) => new Intl.NumberFormat().format(amount);

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>Product Management</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/app">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active" style={{ color: "#c4281c" }}>Product Management</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            {loading && !filter ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                    <Oval height={80} width={80} color="#FF0200" visible={true} ariaLabel="oval-loading" secondaryColor="#4fa94d" strokeWidth={2} strokeWidthSecondary={2} />
                </div>
            ) : (
                <div className="row" id="contactList">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center border-0">
                                <h5 className="card-title mb-0 flex-grow-1" style={{ color: "#c4281b" }}>Product Management</h5>
                            </div>

                            <div className="card-body border border-dashed border-end-0 border-start-0">
                                <div className="d-flex justify-content-end">
                                    <div className="col-xl-2 col-md-4" style={{ marginRight: "5px" }}>
                                        <button
                                            className="vehicle-button btn btn-primary"
                                            style={{ backgroundColor: "#c4281b", color: "#fff", border: "none" }}
                                            onClick={handleShow}
                                        >
                                            <i className="mdi mdi-plus-circle-outline"></i> Add Product
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div className="col-xl-4 col-md-6">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="form-control search"
                                            placeholder="Search to product..."
                                            value={filter}
                                            onChange={handleFilterChange}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle table-nowrap table-striped table-borderless table-hover">
                                        <thead className="table-light text-muted">
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {products.map((product) => (
                                                <tr key={product.id}>
                                                    <td>{product.id}</td>
                                                    <td>{product.product_name}</td>
                                                    <td className="status">
                                                        {product.status === "active" ? (
                                                            <span className="badge bg-success">{product.status}</span>
                                                        ) : (
                                                            <span className="badge bg-info">{product.status}</span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                                                type="button"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="mdi mdi-apps"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li>
                                                                    <Link className="dropdown-item" onClick={() => handleShowView(product)}>
                                                                        <i className="ri-eye-fill text-muted me-2 align-bottom"></i> View Product
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link className="dropdown-item" onClick={() => handleShowEdit(product)}>
                                                                        <i className="mdi mdi-pencil me-2 text-success"></i> Edit
                                                                    </Link>
                                                                </li>
                                                                <li>
                                                                    <Link className="dropdown-item" onClick={() => handleDelete(product.id)}>
                                                                        <i className="mdi mdi-trash-can-outline me-2 text-danger"></i> Delete Product
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end mt-5 mb-5">
                                        <div className="pagination-wrap hstack gap-2">
                                            <ul className="pagination listjs-pagination mb-0">
                                                {(pagination.links || []).map((link, index) => {
                                                    const pageNumber = link.url ? new URL(link.url).searchParams.get("page") : null;
                                                    return (
                                                        <li key={index} className={`page-item ${link.active ? "active" : ""}`}>
                                                            <button
                                                                className="page-link"
                                                                onClick={() => pageNumber && handlePageChange(Number(pageNumber))}
                                                                dangerouslySetInnerHTML={{ __html: link.label }}
                                                            ></button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <CreateCustomProduct show={show} closeModal={handleClose} refreshPage={handleRefresh} />
            <EditCustomProduct showEdit={showEdit} closeModal={handleClose} refreshPage={handleRefresh} product={editProduct} />
            <ViewCustomProduct showView={showView} closeModal={handleClose} refreshPage={handleRefresh} product={viewProduct} />
        </>
    );
}
