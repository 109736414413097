import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";

export default function EditUserPassword({
  showEditPassword,
  closeModal,
  refreshPage,
  userId,
}) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    password: "",
  });

  useEffect(() => {
    // Reset form data when modal is closed
    if (!showEditPassword) {
      resetForm();
    }
  }, [showEditPassword]);

  const resetForm = () => {
    setFormData({
      password: "",
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosInstance.put(
        `/user/auth/user_password/${userId}`, // Use userId here
        {
          password: formData.password,
        }
      );

      if (response.data.success) {
        toast.success("Password updated successfully!");
        refreshPage(); // Refresh the page or update the state
        handleClose(); // Close the modal
      } else {
        setValidationError(response.data.errors || {});
        toast.error(response.data.message || "Update failed.");
      }
    } catch (error) {
      toast.error("An error occurred while updating the password.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="EditUserPassword">
      <Modal
        show={showEditPassword}
        onHide={handleClose}
        dialogClassName="modal-lg"
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!validationError.password}
              />
              <Form.Control.Feedback type="invalid">
                {validationError.password &&
                  validationError.password.join(", ")}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "#c4281b", color: "#fff" }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
