import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export default function EditUserMngt({
  showEdit,
  closeModal,
  refreshPage,
  userDetails,
}) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [userType, setuserType] = useState([]);

  useEffect(() => {
    const fetchRolesAndPermissions = async () => {
      try {
        const user_type = await axiosInstance.get(`/user/auth/get_user_type`);

        // Fetch roles
        const rolesResponse = await axiosInstance.get("/user/roles");

        // Filter out the 'Customer' role
        const filteredRoles = rolesResponse.data.data.roles.data.filter(
          (role) => role.name !== "Customers"
        );

        // Set the state with the filtered roles and user types
        setuserType(user_type.data.data.user_type || []);
        setRoles(filteredRoles);
      } catch (error) {
        console.error("Error fetching roles or permissions:", error);
      }
    };

    fetchRolesAndPermissions();
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    role: "",
    user_type_id: "",
  });

  useEffect(() => {
    if (userDetails) {
      setFormData({
        first_name: userDetails?.first_name || "",
        last_name: userDetails?.last_name || "",
        email: userDetails?.email || "",
        phone: userDetails?.phone || "",
        password: userDetails?.password || "",
        role: userDetails.roles[0]?.name || "",
        user_type_name: userDetails.userType?.user_type_name || "",
      });
      if (userDetails.roles.length > 0) {
        setSelectedRole(userDetails.roles[0].id);
        setSelectedUser(userDetails.user_type_id);
      }
    }
  }, [userDetails]);

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: "",
      user_type_id: "",
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    // resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Extract the role ID from the selectedRole state
      const response = await axiosInstance.put(
        `/user/editUser/${userDetails.id}`,
        {
          first_name: formData.first_name,
          last_name: formData.last_name,
          phone: formData.phone,
          role: selectedRole,
          user_type_id: selectedUser,
        }
      );

      if (response.data.success) {
        // Handle successful response, e.g., show success message
        toast.success("User updated successfully!");
        refreshPage();
        handleClose(); // Close the modal
      } else {
        // Handle errors, e.g., show error messages
        console.error("Update failed:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred while updating the user:", error);
      toast.error("Failed to Update user.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="CreateCSP">
      <Modal show={showEdit} onHide={handleClose} dialogClassName="modal-lg">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User and Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            {/* <Form.Label>Name</Form.Label> */}
            <Form.Group className="mb-3" controlId="formfirstName">
              <div>
                <h6>First Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              {validationError.first_name && (
                <small className="text-danger my-1">
                  {" "}
                  {validationError.first_name[0]}{" "}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastname">
              <div>
                <h6>Last Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
              {validationError.last_name && (
                <small className="text-danger my-1">
                  {" "}
                  {validationError.last_name[0]}{" "}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <div>
                <h6>Email</h6>
              </div>
              <Form.Control
                type="email"
                placeholder="Enter..."
                name="email"
                value={formData.email}
                onChange={handleChange}
                readOnly
                style={{ backgroundColor: "#f0f0f0", color: "#6c757d" }}
              />
              {validationError.email && (
                <small className="text-danger my-1">
                  {" "}
                  {validationError.email[0]}{" "}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <div>
                <h6>Phone Number</h6>
              </div>
              <Form.Control
                type="number"
                placeholder="Enter..."
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationError.phone && (
                <small className="text-danger my-1">
                  {" "}
                  {validationError.phone[0]}{" "}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formRole">
              <Form.Label>User Type</Form.Label>
              <Form.Select
                aria-label="Select Role"
                name="user_type_id"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                <option value="">-- Select User Type --</option>
                {userType.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.user_type_name}
                  </option>
                ))}
              </Form.Select>
              {validationError.user && (
                <small className="text-danger my-1">
                  {validationError.user[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
              <div>
                <h6>Role</h6>
              </div>
              <Form.Select
                aria-label="Select Role"
                name="role"
                // value={formData.role}
                // onChange={handleChange}
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">--select role--</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </Form.Select>
              {validationError.role && (
                <small className="text-danger my-1">
                  {validationError.role[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="link" onClick={handleClose}>
              Close
            </Button> */}
            <Button
              className=""
              style={{ backgroundColor: "#c4281b", color: "#fff" }}
              type="submit"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  updating...
                </>
              ) : (
                "Update"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {/* <ToastContainer /> */}
    </div>
  );
}
