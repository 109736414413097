// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import "./Style.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
// import axios from 'axios';

export default function EditCompany({
    showEdit,
    closeModal,
    refreshPage,
    editSubAccount,
}) {
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
    });

    const resetForm = () => {
        setFormData({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
        });
        setValidationError({});
    };


    useEffect(() => {
        if (editSubAccount) {
            setFormData({
                first_name: editSubAccount?.account_first_name || "",
                last_name: editSubAccount?.account_last_name || "",
                phone: editSubAccount?.account_phone || "",
                email: editSubAccount?.account_email || "", 
            });
           
        }
    }, [editSubAccount]);

    const handleChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleClose = () => {
        // resetForm();
        closeModal();
    };

    //   const handleClose = () => {
    //     resetForm();
    //     closeModal();
    //   }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            // Extract the role ID from the selectedRole state
            const response = await axiosInstance.post(
                `/user/sub_account/sub_accounts`,
                {
                    id: editSubAccount.sub_account_id,
                    user_id: editSubAccount.user_id,
                    last_name: formData.last_name,
                    first_name: formData.first_name,
                    email: formData.email ,
                    phone: formData.phone ,

                }
            );

            if (response.data.success) {
                // Handle successful response, e.g., show success message
                toast.success("Customer updated successfully!");
                refreshPage();
                handleClose(); // Close the modal
            } else {
                // Handle errors, e.g., show error messages
                console.error("Update failed:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while updating the user:", error);
            toast.error("Failed to Update user.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="CreateCSP">
            <Modal show={showEdit} onHide={handleClose} dialogClassName="modal-md">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Sub Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <Form.Label>Name</Form.Label> */}
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>First Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleChange}
                            />
                            {validationError.first_name && (
                                <small className="text-danger my-1">
                                    {" "}
                                    {validationError.first_name[0]}{" "}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Last Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleChange}
                            />
                            {validationError.last_name && (
                                <small className="text-danger my-1">
                                    {" "}
                                    {validationError.last_name[0]}{" "}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formEmail">
                            <div>
                                <h6>Company Email</h6>
                            </div>
                            <Form.Control
                                type="email"
                                placeholder="Enter..."
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                readOnly
                                style={{ backgroundColor: "#f0f0f0", color: "#6c757d" }}
                            />
                            {validationError.email && (
                                <small className="text-danger my-1">
                                    {" "}
                                    {validationError.email[0]}{" "}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Phone</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                            />
                            {validationError.phone && (
                                <small className="text-danger my-1">
                                    {" "}
                                    {validationError.phone[0]}{" "}
                                </small>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            {/* <ToastContainer /> */}
        </div>
    );
}
