import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateManage from "../../../components/Modal/Admin/Renewal/CreateManage";
import EditManage from "../../../components/Modal/Admin/Renewal/EditManage"; // Import EditModal
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";
import AddPayment from "../../../components/Modal/Admin/Renewal/AddPayment";
import ViewManage from "../../../components/Modal/Admin/Renewal/ViewManage";
import ViewPaymentHistory from "../../../components/Modal/Admin/Renewal/ViewPaymentHistory";

export default function Management() {

    const [items, setItems] = useState([]);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);
    const [showManageModal, setShowManageModal] = useState(false);
    const [showPayment, setShowPayment] = useState(false);
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false); // State for EditModal
    const [showViewModal, setShowViewModal] = useState(false); // State for EditModal
    const [selectedItem, setSelectedItem] = useState(null);
    const [paymentHistories, setPaymentHistory] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const [isGeneralLoading, setIsGeneralLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [statusFilter, setStatusFilter] = useState(""); 

    useEffect(() => {
        const fetchItems = async () => {
            setIsGeneralLoading(true);

            try {
                const response = await axiosInstance.get(`user/invoice/fetch`, {
                    params: {
                        page,
                        filter: searchQuery,
                        start_date: startDate, // Pass start date
                        end_date: endDate,     // Pass end date
                        status: statusFilter, 
                    },
                });
                setItems(response.data.data.invoices.data);
                setPagination(response.data.data.invoices);
                console.log(response.data.data.invoices);
                
            } catch (error) {
                console.error("Error fetching items:", error);
            } finally {
                setIsGeneralLoading(false);
            }
        };

        fetchItems();
    }, [page, refreshKey, searchQuery, startDate, endDate, statusFilter]);

    const handleShowManageModal = (item) => {
        setSelectedItem(item);
        setShowManageModal(true);
    };

    const handleShowEditModal = (item) => {
        setSelectedItem(item);
        setShowEditModal(true);
    };
    const handleShowPayment = (item) => {
        setSelectedItem(item);
        setShowPayment(true);
    };

    const handleShowPaymentHistory = (item) => {
        setPaymentHistory(item);
        setShowPaymentHistory(true);
    };

    const handleShowViewInvoice = (item) => {
        setSelectedItem(item);
        setShowViewModal(true);
    };

    const MySwal = withReactContent(Swal);

    const handleDelete = async (itemId) => {
        const result = await MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await axiosInstance.post(`user/invoice/delete/${itemId}`);
                toast.success("Invoice deleted successfully!");
                handleRefresh();
            } catch (error) {
                console.error("Error deleting Invoice:", error);
                toast.error("Error deleting Invoice.");
            }
        }
    };

    const handleCloseManageModal = () => {
        setShowManageModal(false);
        setSelectedItem(null);
    };

    const handleClosePayment = () => {
        setShowPayment(false);
        setSelectedItem(null);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedItem(null);
    };
    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedItem(null);
    };

    const handleCloseViewPaymentHistory = () => {
        setShowPaymentHistory(false);
        setPaymentHistory(null);
    };

    const handleRefresh = () => {
        setRefreshKey((prevKey) => prevKey + 1);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.last_page) {
            setPage(newPage);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setStatusFilter(event.target.value);
    };

    // Function to format amount with commas
    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };



    const [analytic, setAnalytic] = useState(null);

    useEffect(() => {
        const fetchAnalytic = async () => {
            try {
                const response = await axiosInstance.get("/user/fetch-analytic");
                setAnalytic(response.data.data.data);
            } catch (error) {
                console.error("Error fetching analytic:", error);
            }
        };

        fetchAnalytic();
    }, []);

    const sendInvoiceEmail = async (invoiceId) => {
        try {
            // Trigger the email sending API
            await axiosInstance.post(
                `user/invoice/send_invoice_mail/${invoiceId}`
            );
            toast.success("Invoice email sent successfully!");
        } catch (error) {
            console.error("Error sending invoice email:", error);
            toast.error("Failed to send invoice email.");
        }
    };

    return (
        <div className="manage-permissions">
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>
                            Renewal Management
                        </h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/app">Renewal</Link>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    style={{ color: "#c4281c" }}
                                >
                                    Renewal
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}
            {isGeneralLoading && !searchQuery ? (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                >
                    <Oval
                        height={80}
                        width={80}
                        color="#FF0200"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div className="d-flex justify-content-end mb-4">
                                <button
                                    className="vehicle-button btn btn-primary"
                                    style={{ backgroundColor: "#c4281b", color: "#fff", border: "none" }}
                                    onClick={() => setShowManageModal(true)}
                                >
                                    <i className="mdi mdi-plus-circle-outline"></i> Add Fixed Invoice
                                </button>
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-md-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                Start&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                            </span>
                                        </div>
                                    <input
                                        type="date"
                                        className="form-control mb-2"
                                        placeholder="Start Date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        />
                                        </div>
                                </div>
                                <div className="col-xl-4 col-md-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                End&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                            </span>
                                        </div>
                                    <input
                                        type="date"
                                        className="form-control mb-2"
                                        placeholder="End Date"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="row mb-3">
                                <div className="col-xl-4 col-md-6">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="form-control search"
                                            placeholder="Search..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                                
                                <div className="col-xl-4 col-md-6">
                                    <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                Payment Status&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                            </span>
                                        </div>
                                    <select
                                        className="form-select"
                                        value={statusFilter}
                                        onChange={handleStatusFilterChange}
                                    >
                                        <option value="">All Status</option>
                                        <option value="paid">Paid</option>
                                        <option value="unpaid">Unpaid</option>
                                        <option value="partly_paid">Partly Paid</option>
                                        <option value="due">Due</option>
                                        </select>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive-sm">
                                <table
                                    className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                                    id="itemsTable"
                                >
                                    <thead className="table-light text-muted">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Account Name</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Amount Paid</th>
                                            <th scope="col">Balance</th>
                                            <th scope="col">Start Date</th>
                                            <th scope="col">End Date</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {items.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.account.company_name}</td>
                                                <td>{item.product.product_name}</td>
                                                <td>{formatAmount(item.amount)}</td>
                                                <td>{formatAmount(item.discount)}</td>
                                                <td>{formatAmount(item.amount_paid)}</td> 
                                                <td>{formatAmount(item.balance)}</td>
                                                <td>{formatDate(item.start_date)}</td> 
                                                <td>{formatDate(item.end_date)}</td>
                                                <td className="status">
                                                    {item.status === "paid" ? (
                                                        <span className="badge bg-success">
                                                            {item.status}
                                                        </span>
                                                    ) : item.status === "unpaid" ? (
                                                        <span className="badge bg-danger">
                                                            {item.status}
                                                        </span>
                                                    ) : item.status === "partly_paid" ? (
                                                        <span className="badge bg-warning">
                                                            {item.status}
                                                        </span>
                                                    ) : item.status === "due" ? (
                                                        <span className="badge bg-info">
                                                            {item.status}
                                                        </span>
                                                    ) : (
                                                        <span className="badge bg-secondary">
                                                            Unknown Status
                                                        </span>
                                                    )}
                                                </td>

                                                <td>
                                                    <div className="dropdown">
                                                        <button
                                                            className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                                            type="button"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                        >
                                                            <i className="mdi mdi-apps"></i>
                                                        </button>
                                                        
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowViewInvoice(item)}
                                                                >
                                                                    <i className="ri-edit-fill text-muted me-2 align-bottom"></i>
                                                                    View invoice
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowPaymentHistory(item.id)}
                                                                >
                                                                    <i className="ri-edit-fill text-muted me-2 align-bottom"></i>
                                                                    View payment history
                                                                </Link>
                                                            </li>
                                                            
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowEditModal(item)}
                                                                >
                                                                    <i className="ri-edit-fill text-muted me-2 align-bottom"></i>
                                                                    Edit
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowPayment(item)}
                                                                >
                                                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                                                    Add Payment
                                                                </Link>
                                                            </li>
                                                            {/* <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => sendInvoiceEmail(item.id)}  // Trigger send invoice email
                                                                >
                                                                    <i className="ri-mail-fill text-muted me-2 align-bottom"></i>
                                                                    Send Invoice Email
                                                                </Link>
                                                            </li> */}
                                                            <li>
                                                                <Link
                                                                    className="dropdown-item"
                                                                    onClick={() => handleDelete(item.id)}
                                                                >
                                                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                                                    Delete
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="d-flex justify-content-end mt-5 mb-5">
                                    <div className="pagination-wrap hstack gap-2">
                                        <ul className="pagination listjs-pagination mb-0">
                                            {(pagination.links || []).map((link, index) => {
                                                const pageNumber = link.url
                                                    ? new URL(link.url).searchParams.get("page")
                                                    : null;

                                                return (
                                                    <li
                                                        key={index}
                                                        className={`page-item ${link.active ? "active" : ""
                                                            }`}
                                                    >
                                                        <button
                                                            className="page-link"
                                                            onClick={() =>
                                                                pageNumber &&
                                                                handlePageChange(Number(pageNumber))
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html: link.label,
                                                            }}
                                                        ></button>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

            {/* Include the CreateManage modal */}
            {showManageModal && (
                <CreateManage
                    show={showManageModal}
                    closeModal={handleCloseManageModal}
                    item={selectedItem}
                    refreshData={handleRefresh}
                />
            )}

            {/* Include the EditModal */}
            {showEditModal && (
                <EditManage
                    showEdit={showEditModal}
                    closeModal={handleCloseEditModal}
                    invoiceId={selectedItem}
                    refreshData={handleRefresh}
                />
            )}
            {/* Include the EditModal */}
            {showPayment && (
                <AddPayment
                    showPayment={showPayment}
                    closeModal={handleClosePayment}
                    itemData={selectedItem}
                    refreshData={handleRefresh}
                />
            )}

            {showViewModal && (
                <ViewManage
                    showView={showViewModal}
                    closeModal={handleCloseViewModal}
                    Invoice={selectedItem}
                    refreshData={handleRefresh}
                />
            )}
            {showPaymentHistory && (
                <ViewPaymentHistory
                    showHistoryView={showPaymentHistory}
                    closeModal={handleCloseViewPaymentHistory}
                    invoice_id={paymentHistories}
                    refreshData={handleRefresh}
                />
            )}

        </div>
    );
}
