import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";
import { Spinner } from "react-bootstrap";
// import "./CreateUser.css";

export default function CreateAccount({ show, closeModal, refreshPage }) {
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [baseUser, setBaseUser] = useState([]);

    const [formData, setFormData] = useState({
        base_user_id: "",
        company_name: "",
        domain_name: "",
        email: "",
        phone: "",
    });

    const resetForm = () => {
        setFormData({
            base_user: "",
            company_name: "",
            domain_name: "",
            email: "",
            phone: "",
        });
        setValidationError({});
    };

    const handleChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axiosInstance.post(
                "/user/account/add_account",
                formData
            );
            console.log("Account created:", response.data);
            toast.success("Account requested successfully");

            handleClose();
            refreshPage();
        } catch (error) {
            console.error("Error creating credit:", error);

            // Check if the error response has validation errors
            if (error.response && error.response.data && error.response.data.errors) {
                setValidationError(error.response.data.errors); // Set validation errors from backend
            } else {
                toast.error("Error requesting credit. Please try again."); // Show generic error message
            }
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(
                    `/user/base_user/fetch_base_user_no_paginate`
                );
                setBaseUser(response.data.data.base_user);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="CreateUser">
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="accountInput">
                            <div className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        Base User Name &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    </span>
                                </div>
                                <select
                                    className="form-control custom-select"
                                    name="base_user_id"
                                    value={formData.base_user_id}
                                    onChange={(e) =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            base_user_id: e.target.value,
                                        }))
                                    }
                                >
                                    <option value="">-- Select Base user --</option>
                                    {baseUser.map((user) => (
                                        <option
                                            key={user.base_user_id}
                                            value={user.base_user_id}
                                        >
                                            {user.first_name} {user.last_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {validationError.base_user_id && (
                                <small className="text-danger my-1">
                                    {validationError.base_user_id[0]}
                                </small>
                            )}
                        </Form.Group>
                            <br />
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Company Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.company_name && (
                                <small className="text-danger my-1">
                                    {validationError.company_name[0]}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Domain Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="domain_name"
                                value={formData.domain_name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.domain_name && (
                                <small className="text-danger my-1">
                                    {validationError.domain_name[0]}
                                </small>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Email</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {validationError.email && (
                                <small className="text-danger my-1">
                                    {validationError.email[0]}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Phone</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                            {validationError.phone && (
                                <small className="text-danger my-1">
                                    {validationError.phone[0]}
                                </small>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
