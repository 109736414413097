// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";


export default function ViewAccount({ showView, closeModal, refreshPage, account }) {
    const handleClose = () => {
        closeModal();
    };

    const [accounts, setAccount] = useState([]);

    useEffect(() => {
        if (account) {
            setAccount(account);
        }
    }, [account]);

    const accountCards = accounts ? (
        <Card key={accounts.id} className="mb-3">
            <Card.Body>
                {/* <Card.Title>{accounts.user_first_name}</Card.Title> */}
                <Card.Text>
                    <strong>Name:</strong> {accounts.user_first_name}
                    <br />
                    <strong>Company Name:</strong> {accounts.company_name}
                    <br />
                    <strong>Domain Name:</strong> {accounts.domain_name}
                    <br />
                    <strong>Email:</strong> {accounts.account_email}
                    <br />
                    <strong>Phone:</strong> {accounts.account_phone}
                    <br />
                    <strong>Status:</strong> {accounts.status}
                </Card.Text>
            </Card.Body>
        </Card>
    ) : (
        <p>No Account available.</p>
    );

    return (
        <div className="ViewTransaction">
            <Modal show={showView} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>View Account</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: "15px", lineHeight: "30px" }}>{accountCards}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        style={{ backgroundColor: "#c4281b" }}
                        onClick={refreshPage}
                    >
                        Refresh
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
