import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./CreateUser.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Col, Spinner } from "react-bootstrap";

export default function BulkUpload({ show, closeModal, refreshPage }) {
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle file upload submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.error("Please select a file to upload.");
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", file);

        try {
            // Submit the file to the backend for processing
            await axiosInstance.post("/user/base_user/upload-users", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            // Close modal and refresh the page if successful
            handleClose();
            refreshPage();
            toast.success("Users uploaded successfully!");
        } catch (error) {
            console.error("Error uploading file:", error);
            if (error.response && error.response.data.errors) {
                setValidationError(error.response.data.errors);
            }
            toast.error("Failed to upload users.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setFile(null);
        setValidationError({});
        closeModal();
    };

    return (
        <div className="CreateUser">
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk User Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formFileUpload" className="mb-3">
                            <Form.Label>Upload Excel File</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".xlsx, .xls, .csv"
                                onChange={handleFileChange}
                                required
                            />
                            {validationError.file && (
                                <small className="text-danger my-1">
                                    {validationError.file[0]}
                                </small>
                            )}
                        </Form.Group>
                        <Col md={12} className="mb-2">
                            <p className="text-muted">Please ensure your Excel file includes the columns: First Name, Last Name, Email, Phone, and Password.</p>
                        </Col>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Uploading...
                                </>
                            ) : (
                                "Upload"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
