import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Col, Spinner } from "react-bootstrap";
// import "./Style.css";

export default function CreateSubAccount({ ShowSubAccount, closeModal, baseUserId, refreshPage, existingBaseUsers = [], }) {
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [toggle, setToggle] = useState(false);


    const [subAccount, setsubAccount] = useState([
        {
            id: null,
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            status: "",
            password: ""
        },
    ]);
    const resetForm = () => {
        setsubAccount([{
            id: null,
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            status: "",
            password: ""
        }]);
        setValidationError({});
    };


    const handleToggle = () => {
        setToggle(!toggle);
    };
    useEffect(() => {
        if (Array.isArray(existingBaseUsers) && existingBaseUsers.length > 0) {
            setsubAccount(existingBaseUsers);
        }
    }, [existingBaseUsers]);

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const newsubAccount = [...subAccount];
        newsubAccount[index][name] = value;
        setsubAccount(newsubAccount);
    };

    const handleAddSubAccount = () => {
        setsubAccount([
            ...subAccount,
            {
                id: null,
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                status: "",
                password: ""



            },
        ]);
        setValidationError({});

    };

    const handleRemoveSubAccount = (index) => {
        setsubAccount(subAccount.filter((_, i) => i !== index));
    };

    const handleClose = () => {
        resetForm();
        closeModal();
        // handleAddSubAccount();

    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log("Submitted data:", subAccount); // Dummy submission
    //     handleClose();
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const data = subAccount.map((detail) => ({
                ...detail,
                base_user_id: baseUserId,
            }));

            await axiosInstance.post("/user/sub_account/add_sub_account", { details: data });
            toast.success("Sub Accounts saved successfully!");

            handleClose();
            if (refreshPage) refreshPage();
        } catch (error) {
            console.error("Error submitting form:", error);
            if (error.response && error.response.data.errors) {
                setValidationError(error.response.data.errors);
                toast.error("Failed to save sub accounts.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="CreateCompanyDetail">
            <Modal
                show={ShowSubAccount}
                onHide={handleClose}
                dialogClassName="modal-lg"
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Sub Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="card-body">
                            {subAccount.map((detail, index) => (
                                <div
                                    key={index}
                                    className="mb-4"
                                    style={{ border: "1px solid #405189", padding: "1rem" }}
                                >
                                    <div className="text-center">
                                        <b style={{ fontSize: "20px" }}>Sub-Account {index + 1}</b>
                                    </div>
                                    <Form.Group className="mb-3" controlId={`formBaseUser${index}`}>
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter..."
                                            name="first_name"
                                            value={detail.first_name}
                                            onChange={(e) => handleChange(index, e)}
                                            required
                                        />
                                        {validationError.first_name && (
                                            <small className="text-danger my-1">
                                                {validationError.first_name[0]}
                                            </small>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId={`formLastName${index}`}>
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter..."
                                            name="last_name"
                                            value={detail.last_name}
                                            onChange={(e) => handleChange(index, e)}
                                            required
                                        />
                                        {validationError.last_name && (
                                            <small className="text-danger my-1">
                                                {validationError.last_name[0]}
                                            </small>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId={`formEmail${index}`}>
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter..."
                                            name="email"
                                            value={detail.email}
                                            onChange={(e) => handleChange(index, e)}
                                            required
                                        />
                                        {validationError.email && (
                                            <small className="text-danger my-1">
                                                {validationError.email[0]}
                                            </small>
                                        )}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId={`formPhone${index}`}>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter..."
                                            name="phone"
                                            value={detail.phone}
                                            onChange={(e) => handleChange(index, e)}
                                        />
                                        {validationError.phone && (
                                            <small className="text-danger my-1">
                                                {validationError.phone[0]}
                                            </small>
                                        )}
                                    </Form.Group>
                                    <Col md={12} lg={12} xs={12} sm={12} className="mb-2">
                                        <div className="position-relative auth-pass-inputgroup">
                                            <Form.Label>Create Password</Form.Label>
                                            <input
                                                type={toggle ? `text` : `password`}
                                                className="form-control pe-5 password-input"
                                                placeholder="Enter password"
                                                aria-describedby="passwordInput"
                                                onChange={(e) => handleChange(index, e)}
                                                value={detail.password}
                                                name="password"
                                                required
                                            />
                                            <button
                                                className="btn btn-link position-absolute end-0 text-decoration-none text-muted password-addon"
                                                style={{ top: "30px" }}
                                                type="button"
                                                onClick={handleToggle}
                                            >
                                                <i
                                                    className={`${toggle ? "ri-eye-off-fill" : "ri-eye-fill"
                                                        } align-middle`}
                                                ></i>
                                            </button>
                                            <div className="invalid-feedback">Please enter password</div>
                                        </div>
                                    </Col>
                                    
                                    {subAccount.length > 1 && (
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveSubAccount(index)}
                                            >
                                                Remove
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            ))}
                            <Button variant="primary" onClick={handleAddSubAccount}>
                                Add Another Account
                            </Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            // style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
