import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateBaseUser from "../../../components/Modal/Admin/BaseUser/CreateBaseUser";
import EditBaseUser from "../../../components/Modal/Admin/BaseUser/EditBaseUser";
import ViewBaseUser from "../../../components/Modal/Admin/BaseUser/ViewBaseUser";
import CreateSubAccount from "../../../components/Modal/Admin/BaseUser/CreateSubAccount";
import axiosInstance from "../../../hooks/axiosInstance";
import Swal from "sweetalert2";
import { Oval } from "react-loader-spinner";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import BulkUpload from "../../../components/Modal/Admin/BaseUser/BulkUpload";



export default function ObjectivesIndicatorPage() {
  const [BaseUser, setBaseUser] = useState([]);
  const MySwal = withReactContent(Swal);

  const [filter, setFilter] = useState("");
  const [showView, setShowView] = useState(false);
  const [ShowSubAccount, setShowSubAccount] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showBulkUpload, setShowBulkUpload] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [baseUsers, setBaseUsers] = useState([]);
  const [viewBaseUsers, setViewBaseUsers] = useState([]);
  const [pagination, setPagination] = useState({});
  const [editBaseUser, setEditUserDetails] = useState(null);
  const [editBaseUserID, setBaseUserID] = useState(null);


  const handleShow = () => setShow(true);
  const handleBulkUpload = () => setShowBulkUpload(true)
  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setShowView(false);
    setShowSubAccount(false);
    setShowBulkUpload(false)
  };

  useEffect(() => {
    const fetch_base_user = async () => {
      setLoading(true);

      try {
        const response = await axiosInstance.get(
          `/user/base_user/fetch_base_user`,
          {
            params: {
              page,
              filter,
            },
          }
        );

        setBaseUsers(response.data.data.base_user.data || []);
        setPagination(response.data.data.base_user);
      } catch (error) {
        toast.error("Error fetching users:", error);
      }
      finally {
        setLoading(false); // Hide loader after fetching
      }
    };

    fetch_base_user();
  }, [page, filter, refreshKey]);

  const handleShowView = (base_user) => {
    setViewBaseUsers(base_user);
    setShowView(true);
  };
  const handleShowEdit = (user) => {
    setEditUserDetails(user);
    setShowEdit(true);
  };

  const handleShowSubAccount = async (base_user_id) => {
    setBaseUserID(base_user_id);
    setShowSubAccount(true);
  };

  const handleDeleteCustomer = async (base_user_id) => {
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.post(
          `/user/base_user/remove_base_user/${base_user_id}`
        );
        handleRefresh();
        handleClose();
        toast.success("Customer removed successfully!");
      } catch (error) {
        toast("Error deleting customer:", error);
      }
    }
  };

  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pagination.last_page) {
      setPage(newPage);
    }
  };

  useEffect(() => { }, [refreshKey]);

  return (
    <>
      {/* <!-- start page title --> */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>Base User</h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/app">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Base User</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}
      {loading && !filter ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
          <Oval
            height={80}
            width={80}
            color="#FF0200"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (

        <div className="row" id="contactList">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex align-items-center border-0">
                <h5 className="card-title mb-0 flex-grow-1">Base User</h5>
              </div>
              <div className="card-body border border-dashed border-end-0 border-start-0">
                <div className="d-flex justify-content-between">
                  <div className="col-xl-4 col-md-6">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search to user..."
                        value={filter}
                        onChange={handleFilterChange}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div>
                    <button
                      className="vehicle-button btn btn-primary"
                      style={{ backgroundColor: "#c4281b" }}
                      onClick={handleShow}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i> Add Base User
                    </button>
                  </div>
                  {/* <div>
                    <button
                      className="vehicle-button btn btn-primary"
                      style={{ backgroundColor: "#c4281b" }}
                      onClick={handleBulkUpload}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i> Bulk Upload
                    </button>

                  </div> */}
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive table-card">
                  <table
                    className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                    id="customerTable"
                  >
                    <thead className="table-light text-muted">
                      <tr>
                        <th scope="col">#</th>
                        <th className="sort" data-sort="currency_name" scope="col">
                          First Name
                        </th>
                        <th className="sort" data-sort="type" scope="col">
                          Last Name
                        </th>
                        <th className="sort" data-sort="quantity_value" scope="col">
                          Email
                        </th>
                        <th className="sort" data-sort="quantity_value" scope="col">
                          Phone
                        </th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {/* <!--end thead--> */}
                    <tbody className="list form-check-all">
                      {baseUsers.map((user, index) => (
                        <tr key={user.id}>
                          <td>{index + 1}</td>
                          <td>{user.first_name}</td>
                          <td>{user.last_name}</td>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td className="status">
                            {user.status === "active" ? (
                              <span className="badge bg-success">
                                {user.status}
                              </span>
                            ) : (
                              <span className="badge bg-info">
                                {user.status}
                              </span>
                            )}
                          </td>
                          <td>
                            <div className="dropdown">
                              <button
                                className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-apps"></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleShowView(user)}
                                  >
                                    <i className="ri-eye-fill text-muted me-2 align-bottom"></i>
                                    View
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleShowEdit(user)}
                                  >
                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                    Edit
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() => handleShowSubAccount(user.base_user_id)}
                                  >
                                    <i className="ri-star-fill text-muted me-2 align-bottom"></i>
                                    Add Sub-Contact
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    onClick={() =>
                                      handleDeleteCustomer(user.base_user_id)
                                    }
                                  >
                                    <i className="mdi mdi-trash-can-outline me-2 text-danger"></i>
                                    Delete User
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end mt-4 mb-3">
                  <nav>
                    <ul className="pagination">
                      <li
                        className={`page-item ${pagination.current_page === 1 ? "disabled" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() =>
                            handlePageChange(pagination.current_page - 1)
                          }
                          disabled={pagination.current_page === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {Array.from(
                        { length: pagination.last_page },
                        (_, index) => index + 1
                      ).map((pageNumber) => (
                        <li
                          key={pageNumber}
                          className={`page-item ${pageNumber === pagination.current_page
                            ? "active"
                            : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageChange(pageNumber)}
                          >
                            {pageNumber}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${pagination.current_page === pagination.last_page
                          ? "disabled"
                          : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() =>
                            handlePageChange(pagination.current_page + 1)
                          }
                          disabled={
                            pagination.current_page === pagination.last_page
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <BulkUpload
        show={showBulkUpload}
        closeModal={handleClose}
        refreshPage={handleRefresh}
      />
      <CreateBaseUser
        show={show}
        closeModal={handleClose}
        refreshPage={handleRefresh}
      />
      <EditBaseUser
        showEdit={showEdit}
        closeModal={handleClose}
        refreshPage={handleRefresh}
        base_user={editBaseUser}
      />
      <ViewBaseUser
        showView={showView}
        closeModal={handleClose}
        refreshPage={handleRefresh}
        base_user={viewBaseUsers}
      />
      <CreateSubAccount
        ShowSubAccount={ShowSubAccount}
        closeModal={handleClose}
        refreshPage={handleRefresh}
        baseUserId={editBaseUserID}

      />
    </>

  );
}
