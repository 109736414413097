import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Card,
  Spinner,
  Breadcrumb,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";

export default function CreateUserMngt({ show, closeModal, refreshPage }) {
  const [roles, setRoles] = useState([]);
  const [userType, setuserType] = useState([]);
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
    role: "",
    user_type_id: "",
  });

  useEffect(() => {
    const fetchRolesAndPermissions = async () => {
      try {
        const user_type = await axiosInstance.get(`/user/auth/get_user_type`);
        const rolesResponse = await axiosInstance.get("/user/roles");
        const filteredRoles = rolesResponse.data.data.roles.data.filter(
          (role) => role.name !== "Base Users" && role.name !== "Sub Accounts"
        );

        // Set the state with the filtered roles and user types
        setuserType(user_type.data.data.user_type || []);
        setRoles(filteredRoles);
      } catch (error) {
        toast.error("Error fetching roles or user types.");
      }
    };

    fetchRolesAndPermissions();
  }, []);

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      role: "",
      user_type_id: "",
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axiosInstance.post("/user/auth/register", formData);
      handleClose();
      refreshPage();
      toast.success("User saved successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data.errors) {
        setValidationError(error.response.data.errors);
        toast.error("Failed to save user.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="CreateUserMngt">
      <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add New User and Assign Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Oval
              className="d-flex justify-content-center align-items-center"
              height={80}
              width={80}
              color="#FF0200"
              visible={isLoading}
              ariaLabel="oval-loading"
              secondaryColor="#4fa94d"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
            <Row>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter..."
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    isInvalid={!!validationError.first_name}
                  />
                  {validationError.first_name && (
                    <Form.Control.Feedback type="invalid">
                      {validationError.first_name[0]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter..."
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    isInvalid={!!validationError.last_name}
                  />
                  {validationError.last_name && (
                    <Form.Control.Feedback type="invalid">
                      {validationError.last_name[0]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter..."
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!validationError.email}
                  />
                  {validationError.email && (
                    <Form.Control.Feedback type="invalid">
                      {validationError.email[0]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <Form.Group className="mb-3" controlId="formPhoneNumber">
                  <Form.Label>Phone Number</Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter..."
                    name="phone"
                    isInvalid={!!validationError.phone}
                  />

                  {validationError.phone && (
                    <Form.Control.Feedback type="invalid">
                      {validationError.phone[0]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <div className="position-relative auth-pass-inputgroup">
                  <Form.Label>Password</Form.Label>
                  <input
                    type={toggle ? `text` : `password`}
                    className={`form-control pe-5 password-input ${
                      validationError.password ? "is-invalid" : ""
                    }`}
                    placeholder="Enter password"
                    aria-describedby="passwordInput"
                    onChange={handleChange}
                    value={formData.password}
                    name="password"
                  />
                  <button
                    className="btn btn-link position-absolute end-0 text-decoration-none text-muted password-addon"
                    type="button"
                    onClick={handleToggle}
                    style={{ top: "30px" }}
                  >
                    <i className={toggle ? `ri-eye-off-fill` : `ri-eye-fill`} />
                  </button>
                  {validationError.password && (
                    <div className="invalid-feedback">
                      {validationError.password[0]}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <div className="position-relative auth-pass-inputgroup">
                  <Form.Label>Confirm Password</Form.Label>
                  <input
                    type={toggle ? `text` : `password`}
                    className={`form-control pe-5 password-input ${
                      validationError.password_confirmation ? "is-invalid" : ""
                    }`}
                    placeholder="Enter password"
                    aria-describedby="passwordInput"
                    onChange={handleChange}
                    value={formData.password_confirmation}
                    name="password_confirmation"
                  />
                  <button
                    className="btn btn-link position-absolute end-0 text-decoration-none text-muted password-addon"
                    type="button"
                    onClick={handleToggle}
                    style={{ top: "30px" }}
                  >
                    <i className={toggle ? `ri-eye-off-fill` : `ri-eye-fill`} />
                  </button>
                  {validationError.password_confirmation && (
                    <div className="invalid-feedback">
                      {validationError.password_confirmation[0]}
                    </div>
                  )}
                </div>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <Form.Group className="mb-3" controlId="formRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    isInvalid={!!validationError.role}
                  >
                    <option value="">--select role--</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Form.Select>
                  {validationError.role && (
                    <Form.Control.Feedback type="invalid">
                      {validationError.role[0]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                <Form.Group className="mb-3" controlId="formUserType">
                  <Form.Label>User Type</Form.Label>
                  <Form.Select
                    name="user_type_id"
                    value={formData.user_type_id}
                    onChange={handleChange}
                    isInvalid={!!validationError.user_type_id}
                  >
                    <option value="">--select user type--</option>
                    {userType.map((usertype) => (
                      <option key={usertype.id} value={usertype.id}>
                        {usertype.user_type_name}
                      </option>
                    ))}
                  </Form.Select>
                  {validationError.user_type_id && (
                    <Form.Control.Feedback type="invalid">
                      {validationError.user_type_id[0]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              className=""
              style={{ backgroundColor: "#c4281b", color: "#fff" }}
              type="submit"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
