import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateCategory from "../../../components/Modal/Admin/CategoryManagement/CreateCategory";
import SubCategory from "../../../components/Modal/Admin/CategoryManagement/SubCategory";
import EditCategory from "../../../components/Modal/Admin/CategoryManagement/EditCategory";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Oval } from "react-loader-spinner";

import "./Index.css";
import CreateSubCategory from "../../../components/Modal/Admin/CategoryManagement/AddSubcategory";

export default function Category() {
  const MySwal = withReactContent(Swal);
  const [credit, setCategory] = useState([]);
  const [filter, setFilter] = useState("");
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [show2, setShow2] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showView, setShowView] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [current, setCurrentCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [loading, setLoading] = useState(false);

  const filteredCredit = credit.filter((credit) => {
    return credit.name.toLowerCase().includes(filter.toLowerCase());
  });

  const handleShow = () => setShow(true);
  const handleShowEdit = () => setShowEdit(true);
  const handleShowView = () => setShowView(true);
  const handleShow2 = () => setShow2(true);

  const handleClose = () => {
    setShow(false);
    setShow2(false);
    setShowEdit(false);
    setShowView(false);
    setShowSubCategory(false);
  };

  const handleRefresh = () => {
    setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
  };

  const handleDelete = async (id) => {
    // Show confirmation dialog
    const result = await MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        // Proceed with deletion
        await axiosInstance.delete(`/user/category/delete/${id}`);
        toast.success("User deleted successfully!");
        handleRefresh(); // Refresh the list or update the state as needed
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error("Error deleting user.");
      }
    }
  };

  const handleShowSubCategory = (category) => {
    setSubCategory(category);
    setShowSubCategory(true);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= pagination.last_page) {
      setPage(newPage);
    }
  };

  const setCurrent = (data) => {
    setCurrentCategory(data);
  };

  useEffect(() => {
    const fetchCategory = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/user/category/fetch`, {
          params: {
            page,
            filter,
          },
        });

        setCategory(response.data.data.category.data || []);
        setPagination(response.data.data.category);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
      setLoading(false);
    };

    fetchCategory();
  }, [page, filter, refreshKey]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>
              Category Management
            </h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/app">Dashboard</Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#c4281c" }}
                >
                  Manage Category
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {loading && !filter ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
          <Oval
            height={80}
            width={80}
            color="#FF0200"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="row" id="contactList">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header d-flex align-items-center border-0">
                <h5
                  className="card-title mb-0 flex-grow-1"
                  style={{ color: "#c4281b" }}
                >
                  Manage Category
                </h5>
              </div>

              <div className="card-body border border-dashed border-end-0 border-start-0">
                <div className="d-flex justify-content-end">
                  <div>
                    <button
                      className="vehicle-button btn "
                      style={{
                        backgroundColor: "#c4281b",
                        color: "#fff",
                        border: "none",
                      }}
                      onClick={handleShow}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i> Add
                      Category
                    </button>
                  </div>
              </div> <br />
              <div className="col-xl-4 col-md-6">
                <div className="search-box">
                  <input
                    type="text"
                    className="form-control search"
                    placeholder="Search to product..."
                    value={filter}
                    onChange={handleFilterChange}
                  />
                  <i className="ri-search-line search-icon"></i>
                </div>
              </div>
              </div>
              <div className="card-body">
                <div className="col-md-12">
                  <div className="table-responsive table-card">
                    <table
                      className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                      id="customerTable"
                    >
                      <thead className="table-light text-muted">
                        <tr>
                          <th scope="col">#</th>
                          <th>Name</th>
                          {/* <th>Description</th> */}
                          <th>Sub Category</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all">
                        {filteredCredit.map((category, idx) => (
                          <tr key={idx}>
                            <td scope="row">{idx + 1}</td>
                            <td> {category.name}</td>
                            {/* <td> {category.description}</td> */}

                            <td>
                              <div>
                                <button
                                  className="vehicle-button btn btn-sm"
                                  style={{
                                    backgroundColor: "#7764CA",
                                    color: "#fff",
                                    border: "none",
                                    borderRadius: "15px",
                                  }}
                                  onClick={() => {
                                    setCurrent(category);
                                    handleShow2();
                                  }}
                                >
                                  <i className="mdi mdi-plus-circle-outline"></i>{" "}
                                  <span>Attach </span>
                                  <span style={{ fontSize: "15px" }}>
                                    ({category.sub_categories_count})
                                  </span>
                                </button>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`badge p-2 badge-pill ${
                                  category.status === "active"
                                    ? "badge-success-light"
                                    : category.status === "inactive"
                                    ? "badge-danger"
                                    : ""
                                }`}
                              >
                                {category.status}
                              </div>
                            </td>
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="mdi mdi-apps"></i>
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleShowEdit();
                                        setCurrent(category);
                                      }}
                                    >
                                      <i className="mdi mdi-pencil me-2 text-success"></i>{" "}
                                      Edit
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() => {
                                        handleShowSubCategory(category);
                                      }}
                                    >
                                      <i className="mdi mdi-pencil me-2 text-success"></i>{" "}
                                      Add Sub-Category
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      className="dropdown-item"
                                      onClick={() => handleDelete(category.id)}
                                    >
                                      <i className="mdi mdi-trash-can-outline me-2 text-danger"></i>
                                      Delete
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end mt-5 mb-5">
                      <div className="pagination-wrap hstack gap-2">
                        <ul className="pagination listjs-pagination mb-0">
                          {(pagination.links || []).map((link, index) => {
                            const pageNumber = link.url
                              ? new URL(link.url).searchParams.get("page")
                              : null;

                            return (
                              <li
                                key={index}
                                className={`page-item ${
                                  link.active ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() =>
                                    pageNumber &&
                                    handlePageChange(Number(pageNumber))
                                  }
                                  dangerouslySetInnerHTML={{
                                    __html: link.label,
                                  }}
                                ></button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CreateCategory
            show={show}
            closeModal={handleClose}
            refreshPage={handleRefresh}
          />
          <CreateSubCategory
            showSubCategory={showSubCategory}
            closeModal={handleClose}
            refreshPage={handleRefresh}
            category={subCategory}
          />
          <SubCategory
            show={show2}
            closeModal={handleClose}
            refreshPage={handleRefresh}
            currentCategory={current}
          />
          <EditCategory
            showEdit={showEdit}
            closeModal={handleClose}
            refreshPage={handleRefresh}
            currentCategory={current}
          />
          </div>
      )}

    </>
  );
}
