import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import "./Style.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";

export default function SubCategory({
  show,
  closeModal,
  refreshPage,
  currentCategory,
  onDeleteSuccess,
}) {
  const [data, setData] = useState({
    category_name: "",
    sub_category: [],
  });

  const [showSubCategoryModal, setShowSubCategoryModal] = useState(false);

  const handleShowSubCategoryModal = () => setShowSubCategoryModal(true);
  const handleCloseSubCategoryModal = () => setShowSubCategoryModal(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingSubCategory, setEditingSubCategory] = useState(null);

  const [editForm, setEditForm] = useState({
    name: '',
    status: '',
  });

  const handleShowEditModal = (subCategory) => {
    console.log(subCategory);
    
    setEditingSubCategory(subCategory);
    setEditForm({ name: subCategory.name, status: subCategory.status }); // Set initial form data
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => setShowEditModal(false);

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`/user/sub_category/delete/${id}`);
      toast.success("Sub category deleted successfully!");

      // Update data state directly to reflect deletion in the table
      setData((prevState) => ({
        ...prevState,
        sub_category: prevState.sub_category.filter((sc) => sc.id !== id),
      }));

      refreshPage();
    } catch (error) {
      console.error("Error deleting sub category:", error);
      toast.error("Failed to delete sub category.");
    }
  };
  const handleSaveChanges = async () => {
    try {

      toast.success("Sub category updated successfully!");

      // Update data state with updated subcategory
      const updatedSubcategory = { ...editForm, id: editingSubCategory.id }; 
      setData((prevState) => ({
        ...prevState,
        sub_category: prevState.sub_category.filter((sc) => sc.id !== editingSubCategory.id).concat(updatedSubcategory),
      }));
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating sub category:", error);
      toast.error("Failed to update sub category.");
    }
  };

  useEffect(() => {
    if (currentCategory) {
      setData({
        category_name: currentCategory?.name || "",
        sub_category: currentCategory?.sub_categories || [],
      });
    }
  }, [currentCategory]);

  return (
    <div className="CreateCSP">
      <Modal show={show} onHide={closeModal} dialogClassName="modal-md">
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>
              {/* Category{" "} */}
              <span>{data?.category_name}</span>
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive table-card mt-2 mb-2 border border-dashed ">
            <table
              className="table align-middle table-nowrap table table-striped table-borderless table-hover"
              id="customerTable"
            >
              <thead className="table-light text-muted">
                <tr>
                  <th scope="col">#</th>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {data.sub_category.map((sc, idx) => (
                  <tr key={idx}>
                    <td scope="row">{idx + 1}</td>
                    <td> {sc.name}</td>
                    <td>
                      <div
                        className={`badge p-2 badge-pill ${sc.status === "active"
                          ? "badge-success-light"
                          : sc.status === "inactive"
                            ? "badge-danger"
                            : ""
                          }`}
                      >
                        {sc.status}
                      </div>
                    </td>
                    <td>
                      <div className="dropdown">
                        <button
                          className="btn btn-icon btn-sm fs-16 text-muted dropdown"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="mdi mdi-apps"></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={() => handleShowEditModal(sc)}
                            >
                              <i className="mdi mdi-pencil me-2 text-success"></i>{" "}
                              Edit
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              onClick={() => handleDelete(sc.id)}
                            >
                              <i className="mdi mdi-trash-can-outline me-2 text-danger"></i>
                              Delete
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>


      <Modal show={showEditModal} onHide={handleCloseEditModal} dialogClassName="modal-md">
        <Modal.Header closeButton>
          <Modal.Title>Edit Sub Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <h6>Name</h6>
            <Form.Control
              type="text"
              placeholder="Enter..."
              name="name"
              value={editForm.name} // Update with editForm.name
              onChange={(event) => setEditForm({ ...editForm, name: event.target.value })}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <h6>Status</h6>
            <Form.Select
              aria-label="Select Status"
              name="status"
              value={editForm.status} // Update with editForm.status
              onChange={(event) => setEditForm({ ...editForm, status: event.target.value })}
            >
              <option value="">-- Select Status --</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Form.Select>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> 
    </div>
  );
}
