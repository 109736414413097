import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";

export default function ViewManage({
  showView,
  closeModal,
  refreshPage,
  Invoice,
}) {
  const handleClose = () => {
    closeModal();
  };

  const [invoice, setInvoices] = useState([]);

  useEffect(() => {
    if (Invoice) {
      setInvoices(Invoice);
      console.log(Invoice, 88);
      
    }
  }, [Invoice]);

  // Function to format numbers with commas
  const formatAmount = (amount) => {
    if (amount !== undefined && amount !== null) {
      return new Intl.NumberFormat().format(amount);
    }
    return amount;
  };

  const InvoiceCards = Invoice ? (
    <Card key={invoice.id} className="mb-3">
      <Card.Body>
        {/* <Card.Title>{products.product_name}</Card.Title> */}
        <Card.Text>
          <strong>Account Name:</strong> {invoice.account?.company_name}
          <br />
          <strong>Product Name:</strong> {invoice.product?.product_name}
          <br />
          <strong>Amount:</strong> {formatAmount(invoice.amount)}
          <br />
          <strong>Amount Paid:</strong> {formatAmount(invoice.amount_paid)}
          <br />
          <strong>Balance:</strong> {formatAmount(invoice.balance)}
          <br />
          <strong>Start Date:</strong> {invoice.start_date}
          <br />
          <strong>End Date:</strong> {invoice.end_date}
          <br />
          <strong>Circles:</strong> {invoice.circle}
          <br />
          <strong>Discount:</strong> {invoice.discount}
          <br />
          <strong>Note:</strong> {invoice.notes}
          <br />
          <strong>Status:</strong> {invoice.status}
        </Card.Text>
      </Card.Body>
    </Card>
  ) : (
    <p>No invoice available.</p>
  );

  return (
    <div className="ViewTransaction">
      <Modal show={showView} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Invoice Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "15px", lineHeight: "30px" }}>
          {InvoiceCards}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#c4281b", border: "none" }}
            onClick={refreshPage}
          >
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
