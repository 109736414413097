// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import "./CreateUser.css";
// import axios from 'axios';

export default function ViewCSP({ showView, closeModal, refreshPage , base_user}) {
  const handleClose = () => {
    closeModal();
  };

  const [baseUser, setBaseUser] = useState([]);
  
  useEffect(() => {
    if (base_user) {
      setBaseUser(base_user);
    }
  }, [base_user]);

  const UserCards = baseUser ? (
    <Card key={baseUser.id} className="mb-3">
      <Card.Body>
        <Card.Title>{baseUser.name}</Card.Title>
        <Card.Text>
          <strong>First Name:</strong> {baseUser.first_name}
          <br />
          <strong>Last Name:</strong> {baseUser.last_name}
          <br />
          <strong>Email</strong> {baseUser.email}
          <br />
          <strong>Phone</strong> {baseUser.phone}
          <br />
          <strong>Status:</strong> {baseUser.status}
        </Card.Text>
      </Card.Body>
    </Card>
  ) : (
    <p>No Base User available.</p>
  );

  return (
    <div className="ViewTransaction">
      <Modal show={showView} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Base User</Modal.Title>
        </Modal.Header>
        <Modal.Body>{UserCards}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#c4281b" }}
            onClick={refreshPage}
          >
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
