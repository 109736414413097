import { Link, useParams, useLocation } from 'react-router-dom'

export default function SideBar() {
    const { associationId } = useParams()
    const location = useLocation();

    // const activeLinkStyle = {
    //   // backgroundColor: '#c4281b',
    // //   backgroundColor: "#c4281bc4",
    //   backgroundColor: "#D90101",
    //   borderLeft: "3px solid #000",
    // };

    // const activeLink = {
    //     color: 'white', 
    // }
 const activeLinkStyle = {
   // backgroundColor: '#c4281b',
   backgroundColor: "#c4281bc4",
   borderLeft: "3px solid #000",
 };

 const activeLink = {
   color: "#ffffff",
 };
    
    const storedPermissions = localStorage.getItem("userPermissions");
    const permissions = storedPermissions ? JSON.parse(storedPermissions) : [];
    // console.log(permissions, 666);

    // Define the hasPermission function
    const hasPermission = (permission) => {
      return Array.isArray(permissions) && permissions.includes(permission);
    };


    return (
        // <!-- ========== App Menu ========== -->
        <div className="app-menu navbar-menu">
            {/* <!-- LOGO --> */}
            <div className="navbar-brand-box">
                {/* <!-- Dark Logo--> */}
                <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                        <img src="/assets/DW-Logo.png" alt="" height="25" />
                    </span>
                    <span className="logo-lg">
                        <img src="/assets/DW-Logo.png" alt="" height="50"/>
                    </span>
                </Link>
                {/* <!-- Light Logo--> */}
                <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                        {/* <img src="/assets/DW-Logo.png" alt="" height="36" /> */}
                    </span>
                    <span className="logo-lg">
                        <img src="/assets/DW-Logo.png" alt="" height="80" />
                    </span>
                </Link>
                <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
                    id="vertical-hover">
                    <i className="ri-record-circle-line"></i>
                </button>
            </div>

            <div id="scrollbar">
                <div className="container-fluid">

                    <div id="two-column-menu">
                    </div>
                    <ul className="navbar-nav" id="navbar-nav">
                        <li style={location.pathname === '/app/dashboard' ? activeLinkStyle : {}} className="nav-item">
                            <Link className="nav-link menu-link" to="/app/dashboard" style={{color:"white"}}>
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/dashboard' ? activeLink : {}}></i> 
                                <span data-key="t-dashboards" style={location.pathname === '/app/dashboard' ? activeLink : {}} >Dashboard</span>
                            </Link>
                        </li>
                        
                        <li className="menu-title"><span data-key="t-menu">User Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/base_user' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/base_user">
                                <i className="ri-team-line" style={location.pathname === '/app/base_user' ? activeLink : {}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/base_user' ? activeLink : {}}>Manage Base User</span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/base_user/sub_account' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/base_user/sub_account">
                                <i className="ri-team-line" style={location.pathname === '/app/base_user/sub_account' ? activeLink : {}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/base_user/sub_account' ? activeLink : {}}>Sub Account</span>
                            </Link>
                        </li>
                        
                        {hasPermission("view_user") && (
                            <li className="nav-item" style={location.pathname === '/app/acl' ? activeLinkStyle : {}}>
                                <Link className="nav-link menu-link" to="/app/acl">
                                    <i className="ri-team-line" style={location.pathname === '/app/acl' ? activeLink : {}}></i>
                                    <span data-key="t-widgets" style={location.pathname === '/app/acl' ? activeLink : {}}>User Access Level</span>
                                </Link>
                            </li>
                        )}
                        <li className="menu-title"><span data-key="t-menu">Account Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/account' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/account">
                               <i className="ri-team-line" style={location.pathname === '/app/account' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/account' ? activeLink : {}}>Account</span>
                           </Link>
                        </li>
                        <li className="menu-title"><span data-key="t-menu">Product Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/manage-category' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/manage-category">
                               <i className="ri-team-line" style={location.pathname === '/app/manage-category' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/manage-category' ? activeLink : {}}>Category</span>
                           </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/product-management' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/product-management">
                               <i className="ri-team-line" style={location.pathname === '/app/product-management' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/product-management' ? activeLink : {}}>Product</span>
                           </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/custom-product' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/custom-product">
                               <i className="ri-team-line" style={location.pathname === '/app/custom-product' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/custom-product' ? activeLink : {}}>Custom Product</span>
                           </Link>
                        </li>

                        <li className="menu-title"><span data-key="t-menu">Renewal Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/renewal-management' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/renewal-management">
                               <i className="ri-team-line" style={location.pathname === '/app/renewal-management' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/renewal-management' ? activeLink : {}}>Fixed Renewal</span>
                           </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/custom-renewal' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/custom-renewal">
                               <i className="ri-team-line" style={location.pathname === '/app/custom-renewal' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/custom-renewal' ? activeLink : {}}>Custom Renewal</span>
                           </Link>
                        </li>


                        <li className="menu-title"><span data-key="t-menu">Invoicing</span></li>
                        <li className="nav-item" style={location.pathname === '/app/invoicing' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/invoicing">
                               <i className="ri-team-line" style={location.pathname === '/app/invoicing' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/invoicing' ? activeLink : {}}>Invoice</span>
                           </Link>
                        </li>
                        {/* <li className="nav-item" style={location.pathname === '/app/product-management' ? activeLinkStyle : {}}>
                           <Link className="nav-link menu-link" to="/app/product-management">
                               <i className="ri-team-line" style={location.pathname === '/app/product-management' ? activeLink : {}}></i> 
                               <span data-key="t-widgets" style={location.pathname === '/app/product-management' ? activeLink : {}}>Product</span>
                           </Link>
                        </li> */}
                        
                        {/* <li className="nav-item" style={location.pathname === '/app/base_user/objective' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/base_user/objective">
                                <i className="ri-account-pin-circle-line" style={location.pathname === '/app/base_user/objective' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/base_user/objective' ? activeLink : {color: '#B3E5FC'}}>base_user Objective</span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/base_user/manage-indicators' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/base_user/manage-indicators">
                                <i className="ri-account-pin-circle-line" style={location.pathname === '/app/base_user/manage-indicators' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/base_user/manage-indicators' ? activeLink : {color: '#B3E5FC'}}>CSP Indicator</span>
                            </Link>
                        </li> */}

                        {/* <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Account Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/account' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/account">
                                <i className="ri-file-shield-line" style={location.pathname === '/app/account' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/account' ? activeLink : {color: '#B3E5FC'}}>Account</span>
                            </Link>
                        </li> */}
                        {/* <li className="nav-item" style={location.pathname === '/app/project_activity/manage-objective' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/project_activity/manage-objective">
                                <i className="ri-account-pin-circle-line" style={location.pathname === '/app/project_activity/manage-objective' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/project_activity/manage-objective' ? activeLink : {color: '#B3E5FC'}}>Project Objective</span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/project_activity/manage-indicator' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/project_activity/manage-indicator">
                                <i className="ri-account-pin-circle-line" style={location.pathname === '/app/project_activity/manage-indicator' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/project_activity/manage-indicator' ? activeLink : {color: '#B3E5FC'}}>Project Indicator</span>
                            </Link>
                        </li>
                        <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Activity Plan and Budget Setup</span></li>
                        <li className="nav-item" style={location.pathname === '/app/activity-plan' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-plan">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-plan' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-plan' ? activeLink : {color: '#B3E5FC'}}>Activity Management </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/activity-plan/budget-item' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-plan/budget-item">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-plan/budget-item' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-plan/budget-item' ? activeLink : {color: '#B3E5FC'}}>Budget Item </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/activity-plan/project-deficit' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-plan/project-deficit">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-plan/project-deficit' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-plan/project-deficit' ? activeLink : {color: '#B3E5FC'}}>Project Deficit </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/activity-plan/project-surplus' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-plan/project-surplus">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-plan/project-surplus' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-plan/project-surplus' ? activeLink : {color: '#B3E5FC'}}>Project Surplus </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/activity-plan/joint-project' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-plan/joint-project">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-plan/joint-project' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-plan/joint-project' ? activeLink : {color: '#B3E5FC'}}>Joint Project </span>
                            </Link>
                        </li>

                        
                        <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Activity Concept and Approval</span></li>
                        <li className="nav-item" style={location.pathname === '/app/activity-concept' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-concept">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-concept' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-concept' ? activeLink : {color: '#B3E5FC'}}>Activity Concept </span>
                            </Link>
                        </li>


                        <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Procurement Process</span></li>
                        <li className="nav-item" style={location.pathname === '/app/procurement' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/procurement">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/procurement' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/procurement' ? activeLink : {color: '#B3E5FC'}}>New Request </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/procurement/approve-request' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/procurement/approve-request">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/procurement/approve-request' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/procurement/approve-request' ? activeLink : {color: '#B3E5FC'}}>Request (Accountant) </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/procurement/request-procurement' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/procurement/request-procurement">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/procurement/request-procurement' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/procurement/request-procurement' ? activeLink : {color: '#B3E5FC'}}>Request (Procurement) </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/procurement/procurement-committee' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/procurement/procurement-committee">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/procurement/procurement-committee' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/procurement/procurement-committee' ? activeLink : {color: '#B3E5FC'}}>Proc. Committee </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/procurement/country-director' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/procurement/country-director">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/procurement/country-director' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/procurement/country-director' ? activeLink : {color: '#B3E5FC'}}>Country Director </span>
                            </Link>
                        </li>


                        <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Finance and Payment Processing</span></li>
                        <li className="nav-item" style={location.pathname === '/app/finance/payment' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/finance/payment">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/finance/payment' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/finance/payment' ? activeLink : {color: '#B3E5FC'}}>Finance Payment </span>
                            </Link>
                        </li>

                        
                        

                        <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Activity Attendance Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/activity-report' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/activity-report">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/activity-report' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/activity-report' ? activeLink : {color: '#B3E5FC'}}>Activity Report </span>
                            </Link>
                        </li>

                        <li className="menu-title"><span data-key="t-menu" style={{color:"#FFD700"}}>Parameter Management</span></li>
                        <li className="nav-item" style={location.pathname === '/app/user' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/user">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/user' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/user' ? activeLink : {color: '#B3E5FC'}}>User Management </span>
                            </Link>
                        </li>
                        <li className="nav-item" style={location.pathname === '/app/user/donor' ? activeLinkStyle : {}}>
                            <Link className="nav-link menu-link" to="/app/user/donor">
                                <i className="ri-dashboard-2-line" style={location.pathname === '/app/user/donor' ? activeLink : {color: '#B3E5FC'}}></i> 
                                <span data-key="t-widgets" style={location.pathname === '/app/user/donor' ? activeLink : {color: '#B3E5FC'}}>Donor Management </span>
                            </Link>
                        </li>  */}

                        
                        

                    </ul>
                </div>
                {/* <!-- Sidebar --> */}
            </div>

            <div className="sidebar-background"></div>
        </div>
    )
}