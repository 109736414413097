import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateAccount from "../../../components/Modal/Admin/AccountUser/CreateAccount";
import EditAccount from "../../../components/Modal/Admin/AccountUser/EditAccount";
import ViewAccount from "../../../components/Modal/Admin/AccountUser/ViewAccount";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";


export default function AccountUser() {
    const MySwal = withReactContent(Swal);
    const [account, setAccount] = useState([]);
    const [filter, setFilter] = useState("");
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showView, setShowView] = useState(false);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [viewAccount, setViewAccount] = useState([]);
    const [editAccount, setEditAccount] = useState(null);
    const [selectedBaseUserId, setSelectedBaseUserId] = useState(null);
    const [baseUser, setBaseUser] = useState([]);
    const [loading, setLoading] = useState(false);

    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        const fetchBaseUser = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(
                    `/user/base_user/fetch_base_user_no_paginate`
                );
                setBaseUser(response.data.data.base_user || []);
            } catch (error) {
                toast.error("Error fetching users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBaseUser();
    }, []);

    const handleBaseUserSelect = (event) => {
        setSelectedBaseUserId(event.target.value);
    };

    useEffect(() => {
        const fetchAccounts = async () => {
            try {
                const accountResponse = await axiosInstance.get("/user/account/fetch", {
                    params: {
                        page,
                        filter,
                        base_user_id: selectedBaseUserId || "",
                    },
                });
                setAccount(accountResponse.data.data.account.data);
                setPagination(accountResponse.data.data.account);
            } catch (error) {
                console.error("Error fetching Credit:", error);
            }
        };

        fetchAccounts();
    }, [page, filter, refreshKey, selectedBaseUserId]);

    const handleStatusChange = async (accountId, newStatus) => {
        try {
            await axiosInstance.patch(`/user/account/update_status/${accountId}`, {
                status: newStatus,
            });
            toast.success("Status updated successfully!");
            setRefreshKey((prev) => prev + 1);
        } catch (error) {
            toast.error("Error updating status");
        }
    };

    const handleShow = () => setShow(true);
    const handleShowEdit = (account) => {
        setEditAccount(account);
        setShowEdit(true);
    };
    const handleShowView = (account) => {
        setViewAccount(account);
        setShowView(true);
    };

    const handleClose = () => {
        setShow(false);
        setShowEdit(false);
        setShowView(false);
    };
    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.last_page) {
            setPage(newPage);
        }
    };
    const handleRefresh = () => {
        setRefreshKey((prevRefreshKey) => prevRefreshKey + 1);
    };

    const handleDeleteAccount = async (account_id) => {
        const result = await MySwal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        });

        if (result.isConfirmed) {
            try {
                await axiosInstance.post(
                    `/user/account/remove_account/${account_id}`
                );
                handleRefresh();
                handleClose();
                toast.success("Customer removed successfully!");
            } catch (error) {
                toast("Error deleting customer:", error);
            }
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>
                            Account
                        </h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/app">Dashboard</Link>
                                </li>
                                <li
                                    className="breadcrumb-item active"
                                    style={{ color: "#c4281c" }}
                                >
                                    Account
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            {loading && !filter ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                    <Oval
                        height={80}
                        width={80}
                        color="#FF0200"
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#4fa94d"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                </div>
            ) : (
                <div className="row" id="contactList">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center border-0">
                                <h5
                                    className="card-title mb-0 flex-grow-1"
                                    style={{ color: "#c4281b" }}
                                >
                                    Account
                                </h5>
                            </div>
                            <div className="card-body border border-dashed border-end-0 border-start-0">
                                <div className="d-flex justify-content-end">

                                    <div className="col-xl-2 col-md-4">
                                        <button
                                            className="vehicle-button btn btn-primary"
                                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                                            onClick={handleShow}
                                        >
                                            <i className="mdi mdi-plus-circle-outline"></i> Add Account
                                        </button>
                                    </div>
                                </div> <br />

                                <div className="row">
                                    <div className="col-xl-4 col-md-6">
                                        <div className="search-box">
                                            <input
                                                type="text"
                                                className="form-control search"
                                                placeholder="Search to customer..."
                                                value={filter}
                                                onChange={handleFilterChange}
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div> <br />
                                    {/* <div className="col-xl-1"></div> */}
                                    <div className="col-xl-4 col-md-4">
                                        <div className="input-group mb-2">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">
                                                    Select a base user &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </div>
                                            <select
                                                className="custom-select form-control"
                                                onChange={handleBaseUserSelect}
                                            >
                                                <option value="">--select base-user--</option>
                                                {baseUser.map((baseUser) => (
                                                    <option key={baseUser.id} value={baseUser.id}>
                                                        {baseUser.first_name} {baseUser.last_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table
                                        className="table align-middle table-nowrap table table-striped table-borderless table-hover"
                                        id="customerTable"
                                    >
                                        <thead className="table-light text-muted">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th>Name</th>
                                                <th>Company Name</th>
                                                <th>Domain Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Status</th>
                                                {/* <th>Actions</th> */}
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {account.map((account, index) => (
                                                <tr key={account.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{account.user_first_name} {account.user_last_name}</td>
                                                    <td>{account.company_name}</td>
                                                    <td>{account.domain_name}</td>
                                                    <td>{account.account_email}</td>
                                                    <td>{account.account_phone}</td>
                                                    <td>
                                                        <div
                                                            className={`badge p-2 badge-pill ${account.status === "active"
                                                                ? "badge-success-light"
                                                                : account.status === "inactive"
                                                                    ? "badge-danger"
                                                                    : ""
                                                                }`}
                                                        >
                                                            {account.status}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-link text-muted p-1 mt-n1 py-0 text-decoration-none shadow-none"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <i className="ri-more-fill"></i>
                                                            </button>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() =>
                                                                        handleStatusChange(
                                                                            account.account_id,
                                                                            account.status === "active"
                                                                                ? "inactive"
                                                                                : "active"
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="ri-refresh-line align-bottom me-2 text-muted"></i>{" "}
                                                                    {account.status === "active"
                                                                        ? "Mark as Inactive"
                                                                        : "Mark as Active"}
                                                                </button>
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowView(account)}
                                                                >
                                                                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                                                                    View
                                                                </button>
                                                                <button
                                                                    className="dropdown-item"
                                                                    onClick={() => handleShowEdit(account)}
                                                                >
                                                                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                                                                    Edit
                                                                </button>
                                                                <li>
                                                                    <Link
                                                                        className="dropdown-item"
                                                                        onClick={() =>
                                                                            handleDeleteAccount(account.account_id)
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-trash-can-outline me-2 text-danger"></i>
                                                                        Delete User
                                                                    </Link>
                                                                </li>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="d-flex justify-content-end mt-5 mb-5">
                                        <div className="pagination-wrap hstack gap-2">
                                            <ul className="pagination listjs-pagination mb-0">
                                                {(pagination.links || []).map((link, index) => {
                                                    const pageNumber = link.url
                                                        ? new URL(link.url).searchParams.get("page")
                                                        : null;

                                                    return (
                                                        <li
                                                            key={index}
                                                            className={`page-item ${link.active ? "active" : ""
                                                                }`}
                                                        >
                                                            <button
                                                                className="page-link"
                                                                onClick={() =>
                                                                    pageNumber &&
                                                                    handlePageChange(Number(pageNumber))
                                                                }
                                                                dangerouslySetInnerHTML={{ __html: link.label }}
                                                            ></button>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <CreateAccount
                show={show}
                closeModal={handleClose}
                refreshPage={handleRefresh}
            />
            <EditAccount
                showEdit={showEdit}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                account={editAccount}

            />
            <ViewAccount
                showView={showView}
                closeModal={handleClose}
                refreshPage={handleRefresh}
                account={viewAccount}
            />
        </>
    );
}
