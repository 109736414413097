import React, { useState, useEffect } from "react";
import { Tabs, Tab, Container } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import RolePermission from "./RolePermission";
import UserManagement from "./UserManagement";
import "./Style.css";

export default function ManagePermissions() {
  const [analytic, setAnalytic] = useState(null);

  useEffect(() => {
    const fetchAnalytic = async () => {
      try {
        const analytic = await axiosInstance.get("/user/fetch-analytic");

        setAnalytic(analytic.data.data.data);
      } catch (error) {
        console.error("Error fetching analytic:", error);
      }
    };

    fetchAnalytic();
  }, []);

  return (
    <div className="manage-permissions">
      {/* <!-- start page title --> */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0" style={{ color: "#c4281c" }}>
              User Access Level Management
            </h4>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/app">User Access Level</Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  style={{ color: "#c4281c" }}
                >
                  User Access Level
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- end page title --> */}
      <div className="card">
        <div className="card-header">
          <Tabs
            defaultActiveKey="home"
            id="manage-tabs"
            className="d-flex justify-content-around mb-3 nav-tabs"
          >
            <Tab
              eventKey="home"
              title={
                <>
                  Manage All Users&nbsp;{" "}
                  <Badge text="dark" className="badg">
                    {analytic && analytic.user_count}
                  </Badge>
                </>
              }
            >
              <div className="pt-4 pb-3">
                <UserManagement />
              </div>
            </Tab>
            <Tab
              eventKey="profile"
              title={
                <>
                  User Role &nbsp;{" "}
                  <Badge text="dark" className="badg">
                    {analytic && analytic.role}
                  </Badge>
                  &nbsp; Permission Manager
                </>
              }
            >
              <Container className="mt-4 pt-4 pb-3">
                <RolePermission />
              </Container>
            </Tab>
          </Tabs>
        </div>
        <div className="card-body"></div>
      </div>
    </div>
  );
}
