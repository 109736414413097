import { useState, useEffect } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";

export default function AddCustomServicePayment({ showPayment, closeModal, refreshData, itemData }) {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        
        // Fetch all products for the account using itemData.account_id
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get("/user/invoice_custom_service/fetch_no_apge", {
                    params: {
                        account_id: itemData.customer.id, // Use itemData directly as the ID
                    },
                });

                const productsWithPayments = response.data.data.invoices.map(product => ({
                    id: product.id,
                    product_name: product.type === 'custom'
                        ? product.custom_product?.product_name
                        : product.product?.product_name,
                    balance: product.balance,
                    amount_paid: "",
                    date_of_payment: "",
                }));
                setProducts(productsWithPayments);
            } catch (error) {
                console.error("Error fetching products:", error);
                toast.error("Failed to fetch products.");
            }
        };


        if (itemData) {
            fetchProducts();
        }
    }, [itemData]);

    const handleChange = (index, name, value) => {
        setProducts(prevProducts =>
            prevProducts.map((product, i) =>
                i === index ? { ...product, [name]: name === "amount_paid" ? value.replace(/,/g, "") : value } : product
            )
        );
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            // Prepare the payload
            const payload = {
                // inv_cust_service_id: itemData, // Use itemData as the ID
                orders: products.map(product => ({

                    invoice_id: product.id,
                    amount_paid: product.amount_paid,
                    date_of_payment: product.date_of_payment,
                })),
            };

            console.log("Payload:", payload); // Debug to ensure payload structure

            // Send API request
            await axiosInstance.post('/user/payment/create_custom_service_payment', payload);
            toast.success("Payments added successfully!");
            refreshData();
            closeModal();
        } catch (error) {
            console.error("Error submitting payments", error);
            // Check if the error message is related to the remaining balance
            if (error.response && error.response.data.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error("Failed to add payment.");
            }
        } finally {
            setIsLoading(false);
        }
    };


    const handleClose = () => {
        closeModal();
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    return (
        <div className="AddPayment">
            <Modal show={showPayment} onHide={handleClose} dialogClassName="modal-md">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Payments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {products.length > 0 ? (
                            products.map((product, index) => (
                                <div key={index} className="mb-2 p-3" style={{ border: '1px dotted rgba(0, 0, 0, 0.5)' }}>
                                    <button className="btn btn-info d-flex justify-content-center mx-auto mt-1 mb-4" disabled>Invoice {index + 1}</button>

                                    <Row>
                                        <Col md={12} className="mb-2">
                                            <Form.Group className="mb-3" controlId="formProduct">
                                                <Form.Label>Product</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="product"
                                                    value={product.product_name}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Balance</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formatAmount(product.balance)}
                                                    readOnly
                                                    style={{
                                                        backgroundColor: "#f5f5f5",
                                                        color: "#333",
                                                        border: "1px solid #ccc",
                                                        cursor: "not-allowed",
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Amount Paid</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter amount"
                                                    value={formatAmount(product.amount_paid)}
                                                    onChange={(e) => handleChange(index, "amount_paid", e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Date of Payment</Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={product.date_of_payment}
                                                    onChange={(e) => handleChange(index, "date_of_payment", e.target.value)}
                                                    required

                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            ))
                        ) : (
                            <p>No products found for this account.</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {isLoading ? <Spinner animation="border" size="sm" /> : "Save Payments"}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}

