// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Card } from "react-bootstrap";

import "./Style.css";
// import axios from 'axios';

export default function ViewCustomProduct({
  showView,
  closeModal,
  refreshPage,
  product,
}) {
  const handleClose = () => {
    closeModal();
  };

  // Function to format numbers with commas
  const formatAmount = (amount) => {
    if (amount !== undefined && amount !== null) {
      return new Intl.NumberFormat().format(amount);
    }
    return amount;
  };

  const productCards = product ? (
    <Card key={product.id} className="mb-3">
      <Card.Body>
        {/* <Card.Title>{product.product_name}</Card.Title> */}
        <Card.Text>
          <strong>product Name:</strong> {product.product_name}
          <br />
          <strong>Description:</strong> {product.description}
          <br />
          <strong>Status:</strong> {product.status}
        </Card.Text>
      </Card.Body>
    </Card>
  ) : (
    <p>No product available.</p>
  );

  return (
    <div className="ViewTransaction">
      <Modal show={showView} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: "15px", lineHeight: "30px" }}>
          {productCards}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#c4281b", border: "none" }}
            onClick={refreshPage}
          >
            Refresh
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
