import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
// import "./CreateUser.css";

export default function EditAccount({ showEdit, closeModal, refreshPage, account }) {
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [baseUser, setBaseUser] = useState(false);
    const [formData, setFormData] = useState({
        base_user_id: "",
        company_name: "",
        domain_name: "",
        email: "",
        phone: "",
    });

    const resetForm = () => {
        setFormData({
            base_user_id: "",
            company_name: "",
            domain_name: "",
            email: "",
            phone: "",
        });
        setValidationError({});
    };

    useEffect(() => {
        const fetchBaseUser = async () => {
            setIsLoading(true);
            try {
                const response = await axiosInstance.get(
                    `/user/base_user/fetch_base_user_no_paginate`
                );
                setBaseUser(response.data.data.base_user || []);
            } catch (error) {
                toast.error("Error fetching users:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBaseUser();
    }, []);


    const handleChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    useEffect(() => {
        if (account) {
            setFormData({
                base_user_id: account.user_id || "",
                account_id: account.account_id || "",
                company_name: account.company_name || "",
                domain_name: account.domain_name || "",
                email: account.account_email || "",
                phone: account.account_phone || "",
            });
        }
    }, [account]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await axiosInstance.post(`/user/account/update_account`, formData);
            toast.success("Account updated successfully!");
            refreshPage(); 
            handleClose(); 
        } catch (error) {
            if (error.response && error.response.data.errors) {
                setValidationError(error.response.data.errors);
            } else {
                toast.error("An error occurred while updating the account.");
            }
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="CreateUser">
            <Modal show={showEdit} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit User Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    Base user &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                            </div>
                            <select
                                className="custom-select form-control"
                                value={formData.base_user_id}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        base_user_id: e.target.value,
                                    }))
                                }
                                isInvalid={!!validationError.base_user_id}
                            >
                                <option value="">-- Select Customer --</option>
                                {baseUser && baseUser.map((baseUser) => (
                                    <option key={baseUser.id} value={baseUser.id}>
                                        {baseUser.first_name} {baseUser.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <br />
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Company Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="company_name"
                                value={formData.company_name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.company_name && (
                                <small className="text-danger my-1">
                                    {validationError.company_name[0]}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Domain Name</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="domain_name"
                                value={formData.domain_name}
                                onChange={handleChange}
                                required
                            />
                            {validationError.domain_name && (
                                <small className="text-danger my-1">
                                    {validationError.domain_name[0]}
                                </small>
                            )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Email</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {validationError.email && (
                                <small className="text-danger my-1">
                                    {validationError.email[0]}
                                </small>
                            )}
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicname">
                            <div>
                                <h6>Phone</h6>
                            </div>
                            <Form.Control
                                type="text"
                                placeholder="Enter..."
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                            {validationError.phone && (
                                <small className="text-danger my-1">
                                    {validationError.phone[0]}
                                </small>
                            )}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    Saving...
                                </>
                            ) : (
                                "Save"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
