import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import axiosInstance from "../../../../hooks/axiosInstance";
import {
    Button,
    Form,
    Modal,
    Row,
    Col,
    Spinner,
} from "react-bootstrap";

export default function EditCustomManage({ showEdit, closeModal, refreshData, invoiceId }) {
    const [products, setProducts] = useState([]);
    const [account, setAccount] = useState([]);
    const [originalAmount, setOriginalAmount] = useState("");

    const [formData, setFormData] = useState({
        account_id: "",
        product_id: "",
        amount: "",
        start_date: "",
        notes: "",
        status: "",
        circle: "",
        end_date: "",
        discount:""
    });

    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (invoiceId) {
            setFormData({
                account_id: invoiceId.account_id || "", // Assuming account_id is directly available in invoiceId
                product_id: invoiceId.product_id || "",
                amount: invoiceId.amount || "",
                start_date: invoiceId.start_date || "",
                notes: invoiceId.notes || "",
                status: invoiceId.status || "",
                circle: invoiceId.circle || "",
                end_date: invoiceId.end_date || "",
                discount: invoiceId.discount || "",

            });
            setOriginalAmount(invoiceId.amount); // Store original amount
        }
    }, [invoiceId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/user/account/getAccountWithoutPaginate');
                setAccount(response.data.data.account);
            } catch (error) {
                toast.error("Error fetching data", error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosInstance.get(`/user/custom_product/fetch_no_paginate`);
                setProducts(response.data.data.products);
            } catch (error) {
                console.error("Error fetching products", error);
            }
        };
        fetchProducts();
    }, []);

    const resetForm = () => {
        setFormData({
            account_id: "",
            product_id: "",
            amount: "",
            start_date: "",
            notes: "",
            circle: "",
            status: "",
            end_date: "",
            discount:""
        });
        setValidationError({});
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "amount") {
            // Remove any commas in the value to keep the raw number
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value.replace(/,/g, ""), // Remove commas before saving
            }));
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }

        // Prefill amount if product is selected
        if (name === "product_id") {
            const selectedProduct = products.find((product) => product.id === Number(value));
            setFormData((prevFormData) => ({
                ...prevFormData,
                amount: selectedProduct ? selectedProduct.amount : "",
            }));
            setOriginalAmount(selectedProduct ? selectedProduct.amount : "");
        }

        // Calculate balance
        if (name === "amount_paid") {
            const balance = formData.amount - parseFloat(value || 0);
            setFormData((prevFormData) => ({
                ...prevFormData,
                balance: balance.toFixed(2),
            }));
        }
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const startDate = new Date(formData.start_date);
            const circleMonths = parseInt(formData.circle, 10); // Number of months to add

            // Add the circle months to start_date to calculate end_date
            const endDate = new Date(startDate.setMonth(startDate.getMonth() + circleMonths));

            // Update formData with end_date
            const updatedFormData = {
                ...formData,
                end_date: endDate.toISOString().split('T')[0] // Format the date as YYYY-MM-DD
            };
            console.log("Updated Form Data: ", updatedFormData);

            await axiosInstance.post(`/user/custom_invoice/update_invoice/${invoiceId.id}`, updatedFormData);
            refreshData();
            handleClose();
            toast.success("Invoice updated successfully!");
        } catch (error) {
            console.error("Error submitting form", error);
        }
        setIsLoading(false);
    };

    const formatAmount = (amount) => {
        return new Intl.NumberFormat().format(amount);
    };

    return (
        <div className="EditInvoice">
            <Modal show={showEdit} onHide={handleClose} dialogClassName="modal-lg">
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Custom Renewal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAccount">
                                    <div>
                                        <h6>Account Name</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Account"
                                        name="account_id"
                                        value={formData.account_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Account</option>
                                        {account.map((acc) => (
                                            <option key={acc.id} value={acc.id}>
                                                {acc.company_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.account_id && (
                                        <small className="text-danger my-1">{validationError.account_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formProduct">
                                    <div>
                                        <h6>Product</h6>
                                    </div>
                                    <Form.Select
                                        aria-label="Select Product"
                                        name="product_id"
                                        value={formData.product_id}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select Product</option>
                                        {products.map((prod) => (
                                            <option key={prod.id} value={prod.id}>
                                                {prod.product_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    {validationError.product_id && (
                                        <small className="text-danger my-1">{validationError.product_id[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formAmount">
                                    <div>
                                        <h6>Amount</h6>
                                    </div>
                                    <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        name="amount"
                                        value={formatAmount(formData.amount)}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.amount}
                                    />
                                    {validationError.amount && (
                                        <small className="text-danger my-1">{validationError.amount[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formDiscount">
                                    <Form.Label>Discount (%)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter circle"
                                        name="discount"
                                        value={formData.discount}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.discount}
                                    />
                                    {validationError.discount && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.discount[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formStartDate">
                                    <div>
                                        <h6>Start Date</h6>
                                    </div>
                                    <Form.Control
                                        type="date"
                                        name="start_date"
                                        value={formData.start_date}
                                        onChange={handleChange}
                                        required
                                    />
                                    {validationError.start_date && (
                                        <small className="text-danger my-1">{validationError.start_date[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <div>
                                        <h6>Cycles (Months)</h6>
                                    </div>
                                    <Form.Control
                                        type="number"
                                        placeholder="Circle (Months)"
                                        name="circle"
                                        value={formData.circle}
                                        onChange={handleChange}
                                        required
                                    />
                                    {validationError.circle && (
                                        <small className="text-danger my-1">{validationError.circle[0]}</small>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formEndDate">
                                    <div>
                                        <h6>End Date</h6>
                                    </div>
                                    <Form.Control
                                        type="date"
                                        name="end_date"
                                        value={formData.end_date}
                                        style={{
                                            backgroundColor: '#f5f5f5',
                                            color: '#333',
                                            border: '1px solid #ccc',
                                            cursor: 'not-allowed',
                                        }}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} lg={6} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formCircle">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select
                                        name="status"
                                        value={formData.status}
                                        onChange={handleChange}
                                        isInvalid={!!validationError.status}
                                    >
                                        <option value="">Select status</option>
                                        <option value="unpaid">Unpaid</option>
                                        <option value="paid">Paid</option>
                                        <option value="partly_paid">Partly Paid</option>
                                        <option value="due">Due</option>
                                    </Form.Select>
                                    {validationError.status && (
                                        <Form.Control.Feedback type="invalid">
                                            {validationError.status[0]}
                                        </Form.Control.Feedback>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={12} lg={12} xs={12} sm={12} className="mb-2">
                                <Form.Group className="mb-3" controlId="formNotes">
                                    <div>
                                        <h6>Notes</h6>
                                    </div>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Additional Notes"
                                        name="notes"
                                        value={formData.notes}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button
                            className=""
                            style={{ backgroundColor: "#c4281b", color: "#fff" }}
                            type="submit"
                            disabled={isLoading} // Disable button while loading
                        >
                            {isLoading ? (
                                <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{" "}
                                    updating...
                                </>
                            ) : (
                                "Update"
                            )}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    );
}
