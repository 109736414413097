import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../hooks/axiosInstance";

export default function ViewPaymentHistory({
    showHistoryView,
    closeModal,
    refreshPage,
    invoice_id,
}) {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pagination, setPagination] = useState({});
    const [page, setPage] = useState(1);



    const handleClose = () => {
        closeModal();
    };

    // Function to format numbers with commas
    const formatAmount = (amount) => {
        if (amount !== undefined && amount !== null) {
            return new Intl.NumberFormat().format(amount);
        }
        return amount;
    };
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.last_page) {
            setPage(newPage);
        }
    };

    // Fetch payment history when the component mounts or invoice_id changes
    useEffect(() => {
        if (invoice_id) {
            axiosInstance.get(`/user/payment/fetch_fixed_payment_history/${invoice_id}`,
                {
                    params: { page }
                })
                .then((response) => {

                    setPaymentHistory(response.data.data.payment_history.data); // Assume API returns data as an array
                    setPagination(response.data.data.payment_history);

                    setLoading(false);
                })
                .catch((error) => {
                    setError("Error fetching payment history.");
                    setLoading(false);
                });
        }
    }, [invoice_id, page]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
        });
    };

    // If loading, show a loading message
    if (loading) {
        return (
            <Modal show={showHistoryView} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Loading Payment History...</Modal.Title>
                </Modal.Header>
                <Modal.Body>Loading...</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    // If there's an error
    if (error) {
        return (
            <Modal show={showHistoryView} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{error}</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    // Render the payment history table if data is available
    const paymentTable = paymentHistory.length ? (
        <div className="card-body">
            <div className="table-responsive-sm">
                <table
                    className="table align-middle table-nowrap table table-striped table-border table-hover"
                    id="itemsTable"
                >
                    <thead>
                        <tr>
                            <th width="20%">#</th>
                            <th width="40%">Payment Date</th>
                            <th width="40%">Amount_paid</th>
                            {/* <th>Payment Method</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistory.map((payment, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{formatDate(payment.date_of_payment)}</td>
                                <td>{formatAmount(payment.amount_paid)}</td>
                                {/* <td>{payment.payment_method}</td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    ) : (
        <p>No payment history available.</p>
    );

    return (
        <div className="ViewTransaction">
            <Modal show={showHistoryView} onHide={handleClose} dialogClassName="modal-md">
                <Modal.Header closeButton>
                    <Modal.Title>Payment History</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: "15px", lineHeight: "30px" }}>
                    {paymentTable}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        style={{ backgroundColor: "#c4281b", border: "none" }}
                        onClick={refreshPage}
                    >
                        Refresh
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
