import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";

export default function EditProduct({ showEdit, closeModal, refreshPage, product }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    product_name: "",
    category_id: "",
    sub_category_id: "",
    status: "",
    amount: "",
    description: "",
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  // Fetch categories, units of measure, and groups
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/user/category/fetch');
        setCategories(response.data.data.category.data);

      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  // Fetch subcategories when category changes
  useEffect(() => {
    const fetchSubCategories = async () => {
      if (formData.category_id) {
        try {
          const response = await axiosInstance.get(`/user/category/getSubCategory?category_id=${formData.category_id}`);
          setSubCategories(response.data.data.subCategories);
        } catch (error) {
          console.error("Error fetching subcategories", error);
        }
      }
    };

    fetchSubCategories();
  }, [formData.category_id]);

  useEffect(() => {
    if (product) {
      setFormData({
        product_name: product.product_name,
        category_id: product.category_id,
        sub_category_id: product.sub_category_id,
        status: product.status,
        amount: product.amount,
        description: product.description,
      });
    }
  }, [product]);

  const resetForm = () => {
    setFormData({
      product_name: "",
      category_id: "",
      sub_category_id: "",
      status: "",
      amount: "",
      description: "",
    });
    setValidationError({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axiosInstance.post(`/user/product/edit/${product.id}`, formData);
      refreshPage();
      handleClose();
      toast.success("Product updated successfully!");
    } catch (error) {
      toast.error("Error updating product", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="EditProduct">
      <Modal show={showEdit} onHide={handleClose} dialogClassName="modal-md">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Product Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicName">
              <div>
                <h6>Product Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="product_name"
                value={formData.product_name}
                onChange={handleChange}
              />
              {validationError.product_name && (
                <small className="text-danger my-1">{validationError.product_name[0]}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCategory">
              <div>
                <h6>Category</h6>
              </div>
              <Form.Select
                aria-label="Select Category"
                name="category_id"
                value={formData.category_id}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.id}>
                    {cat.name}
                  </option>
                ))}
              </Form.Select>
              {validationError.category_id && (
                <small className="text-danger my-1">{validationError.category_id[0]}</small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formSubCategory">
              <div>
                <h6>Sub-category</h6>
              </div>
              <Form.Select
                aria-label="Select Sub-category"
                name="sub_category_id"
                value={formData.sub_category_id}
                onChange={handleChange}
              >
                <option value="">Select Sub-category</option>
                {subCategories.map((subCat) => (
                  <option key={subCat.id} value={subCat.id}>
                    {subCat.name}
                  </option>
                ))}
              </Form.Select>
              {validationError.sub_category_id && (
                <small className="text-danger my-1">{validationError.sub_category_id[0]}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPrice">
              <div>
                <h6>Amount</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="amount"
                value={formData.amount}
                onChange={handleChange}
              />
              {validationError.amount && (
                <small className="text-danger my-1">{validationError.amount[0]}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription2">
              <div>
                <h6>Description</h6>
              </div>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter..."
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
              {validationError.description && (
                <small className="text-danger my-1">{validationError.description[0]}</small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formUnitOfMeasure">
              <div>
                <h6>Status</h6>
              </div>
              <Form.Select
                aria-label="Select Status"
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="">-- Select Status --</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Select>
              {validationError.status && (
                <small className="text-danger my-1">
                  {validationError.status[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? "Saving..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
