import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import axiosInstance from "../../../../hooks/axiosInstance";
import { Spinner } from "react-bootstrap";

export default function CreateSubCategory({
    showSubCategory,
    closeModal,
    refreshPage,
    category
}) {
    const [formData, setFormData] = useState({
        name: "",
        status: "",
    });
    const [validationError, setValidationError] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const resetForm = () => {
        setFormData({
            name: "",
            status: "",
        });
        setValidationError({});
    };
    useEffect(() => {
       console.log(category, 88);
       
    }, [category]);

    const handleChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [e.target.name]: e.target.value,
        }));
    };
    const dataToSubmit = {
        ...formData,
        category_id: category?.id, // Assuming the category has an 'id' field
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await axiosInstance.post("/user/sub_category/create", dataToSubmit)
            toast.success("Sub category created successfully!");
            refreshPage();
            resetForm();
            handleClose();
        } catch (error) {
            console.error("Error submitting form:", error);
            if (error.response && error.response.data.errors) {
                setValidationError(error.response.data.errors);
                toast.error("Failed to save.");
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        resetForm();
        closeModal();
        refreshPage();
    };

    return (
        <Modal show={showSubCategory} onHide={handleClose} dialogClassName="modal-md">
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>
                            Attach Sub Category to{" "}
                            <span>{category?.name}</span>
                        </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <h6>Name</h6>
                        <Form.Control
                            type="text"
                            placeholder="Enter..."
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                        {validationError.name && (
                            <small className="text-danger my-1">
                                {validationError.name[0]}
                            </small>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <h6>Status</h6>
                        <Form.Select
                            aria-label="Select Status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                        >
                            <option value="">-- Select Status --</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </Form.Select>
                        {validationError.status && (
                            <small className="text-danger my-1">
                                {validationError.status[0]}
                            </small>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button
                        style={{
                            backgroundColor: "#c4281b",
                            color: "#fff",
                            border: "none",
                        }}
                        type="submit"
                    >
                        Attach
                    </Button> */}
                    <Button
                        className=""
                        style={{ backgroundColor: "#c4281b", color: "#fff" }}
                        type="submit"
                        disabled={isLoading} // Disable button while loading
                    >
                        {isLoading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{" "}
                                Attaching...
                            </>
                        ) : (
                            "Attach"
                        )}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
