import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./CreateUser.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Col, Spinner } from "react-bootstrap";

export default function CreateBaseUser({ show, closeModal, refreshPage }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "", 
    email: "",
    phone: "",
    status: "",
    password:""
  });

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      status: "",
      password:""
    });
    setValidationError({});
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Submit form data
      await axiosInstance.post("/user/base_user/add_base_user", formData); // Adjust endpoint as necessary
      handleClose();
      refreshPage(); // Refresh the page or data as needed
      toast.success("Base user created successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle validation errors
      if (error.response && error.response.data.errors) {
        setValidationError(error.response.data.errors);
        toast.error("Failed to save user.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  return (
    <div className="CreateUser">
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>First Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              {validationError.first_name && (
                <small className="text-danger my-1">
                  {validationError.first_name[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Last Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
              {validationError.last_name && (
                <small className="text-danger my-1">
                  {validationError.last_name[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Email</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {validationError.email && (
                <small className="text-danger my-1">
                  {validationError.email[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Phone</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationError.phone && (
                <small className="text-danger my-1">
                  {validationError.phone[0]}
                </small>
              )}
            </Form.Group>
            <Col md={12} lg={12} xs={12} sm={12} className="mb-2">
              <div className="position-relative auth-pass-inputgroup">
                <Form.Label>Create Password</Form.Label>
                <input
                  type={toggle ? `text` : `password`}
                  className="form-control pe-5 password-input"
                  placeholder="Enter password"
                  aria-describedby="passwordInput"
                  onChange={handleChange}
                  value={formData.password}
                  name="password"
                  required
                />
                <button
                  className="btn btn-link position-absolute end-0 text-decoration-none text-muted password-addon"
                  style={{ top: "30px" }}
                  type="button"
                  onClick={handleToggle}
                >
                  <i
                    className={`${toggle ? "ri-eye-off-fill" : "ri-eye-fill"
                      } align-middle`}
                  ></i>
                </button>
                <div className="invalid-feedback">Please enter password</div>
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className=""
              style={{ backgroundColor: "#c4281b", color: "#fff" }}
              type="submit"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
