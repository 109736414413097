import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Card,
  Spinner,
  Breadcrumb,
  Row,
  Col,
} from "react-bootstrap";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const RolePermission = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [rolePermissions, setRolePermissions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchRolesAndPermissions = async () => {
      setIsLoading(true);
      try {
        const rolesResponse = await axiosInstance.get("/user/roles");
        const permissionsResponse = await axiosInstance.get(
          "/user/roles/permissions"
        );
        setRoles(rolesResponse.data.data.roles.data);
        setPermissions(permissionsResponse.data.data.permissions);
      } catch (error) {
        console.error("Error fetching roles or permissions:", error);
      }
      setIsLoading(false);
    };

    fetchRolesAndPermissions();
  }, []);

  useEffect(() => {
    if (selectedRole) {
      const fetchRolePermissions = async () => {
        setIsLoading(true);

        try {
          const response = await axiosInstance.get(
            `/user/roles/${selectedRole}/permissions`
          );

          const permissions = response.data.data.permissions;

          // Adjust this line according to the actual structure of the permissions array
          const permissionIds = permissions.map((permission) => permission);

          setRolePermissions(permissionIds);
        } catch (error) {
          console.error("Error fetching role permissions:", error);
        }
        setIsLoading(false);
      };

      fetchRolePermissions();
    } else {
      setRolePermissions([]);
    }
  }, [selectedRole]);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handlePermissionChange = (e) => {
    const permissionId = parseInt(e.target.value, 10);
    const isChecked = e.target.checked;

    if (isChecked) {
      setRolePermissions([...rolePermissions, permissionId]);
    } else {
      setRolePermissions(rolePermissions.filter((id) => id !== permissionId));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axiosInstance.put(`/user/roles/${selectedRole}/permissions`, {
        permissions: rolePermissions,
      });
      toast.success("Permissions updated successfully!");
    } catch (error) {
      toast.error("Failed to update permissions.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? ( // Conditional rendering for the loader
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <Oval
            height={80}
            width={80}
            color="#FF0200"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <Row className="justify-content-center">
          <Col md={12} lg={12} xs={12} sm={12}>
            <div className="bordere p-5">
              <Form onSubmit={handleSubmit}>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Select Role</span>
                  </div>
                  <select
                    value={selectedRole}
                    onChange={handleRoleChange}
                    className="form-control"
                  >
                    <option value="">-- Select Role --</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedRole && (
                  <div className="permissions-list mt-5">
                    <div className="row mb-3">
                      {permissions.map((perm) => (
                        <div className="col-md-4 mb-4" key={perm.name}>
                          <Form.Check
                            style={{
                              fontSize: "13px",
                              color: "#414757",
                              fontWeight: "400px",
                            }}
                            key={perm.id}
                            type="checkbox"
                            label={perm.name}
                            id={`permission-${perm.id}`}
                            value={perm.id}
                            checked={rolePermissions.includes(perm.id)}
                            onChange={handlePermissionChange}
                            aria-labelledby={`permission-${perm.id}`}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        type="submit"
                        className="attach-btn"
                        disabled={isLoading}
                      >
                        {isLoading ? "Attaching..." : "Attach Permission"}
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default RolePermission;
