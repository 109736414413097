import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "./CreateUser.css";
import axiosInstance from "../../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

export default function EditBaseUser({ showEdit, closeModal, refreshPage, base_user }) {
  const [validationError, setValidationError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [baseUsers, setBaseUser] = useState([]);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    status: "",
  });

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      status: "",
    });
    setValidationError({});
  };

  useEffect(() => {
    if (base_user) {
      setFormData({
        first_name: base_user?.first_name || "",
        last_name: base_user?.last_name || "",
        email: base_user?.email || "",
        phone: base_user?.phone || "",
        password: base_user?.password || "",
      });
      
    }
  }, [base_user]);

  const handleChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => {
    resetForm();
    closeModal();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Extract the role ID from the selectedRole state
      const response = await axiosInstance.post(
        `/user/base_user/edit_base_user`,
        {
          id: base_user.base_user_id,
          first_name: formData.first_name,
          last_name: formData.last_name,
          phone: formData.phone,
        }
      );

      if (response.data.success) {
        // Handle successful response, e.g., show success message
        toast.success("Base user updated successfully!");
        refreshPage();
        handleClose(); // Close the modal
      } else {
        // Handle errors, e.g., show error messages
        console.error("Update failed:", response.data.message);
      }
    } catch (error) {
      console.error("An error occurred while updating the user:", error);
      toast.error("Failed to Update user.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="CreateUser">
      <Modal show={showEdit} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit User Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>First Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
              {validationError.first_name && (
                <small className="text-danger my-1">
                  {validationError.first_name[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Last Name</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
              {validationError.last_name && (
                <small className="text-danger my-1">
                  {validationError.last_name[0]}
                </small>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Email</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="email"
                value={formData.email}
                onChange={handleChange}
                readOnly
                style={{ backgroundColor: "#f0f0f0", color: "#6c757d" }}

              />
              {validationError.email && (
                <small className="text-danger my-1">
                  {validationError.email[0]}
                </small>
              )}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <div>
                <h6>Phone</h6>
              </div>
              <Form.Control
                type="text"
                placeholder="Enter..."
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationError.phone && (
                <small className="text-danger my-1">
                  {validationError.phone[0]}
                </small>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className=""
              style={{ backgroundColor: "#c4281b", color: "#fff" }}
              type="submit"
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{" "}
                  Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
